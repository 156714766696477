import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetFinishedExam = ({ uid, isAdmin }, update, updating) => {
  const fetcher = async () => {
    // const utc = new Date().getTimezoneOffset();
    const { data } = isAdmin
      ? await apis.getFinishedExamAdmin({ uid })
      : await apis.getFinishedExam({ uid });
    return data;
  };
  return useQuery(["finished", update, updating, isAdmin, uid], fetcher);
};

export default useGetFinishedExam;
