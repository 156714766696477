import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetDefaultExam = ({ uid, isAdmin }, update, updating) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = isAdmin
      ? await apis.getDefaultExamAdmin({ utc, uid })
      : await apis.getDefaultExam({ utc, uid });
    return data;
  };
  return useQuery(["defaultExam", uid, isAdmin, update, updating], fetcher);
};

export default useGetDefaultExam;
