import React, { useEffect, useState } from "react";
// import ReactApexChart from 'react-apexcharts';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { device } from "../hooks/device";
import DonutGraph from "./DonutGraph";
import useGetReportAIEvent from "../hooks/useGetReportAIEvent";
import useGetReportAIJudge from "../hooks/useGetReportAIJudge";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../atoms";
import dayjs, { locale } from "dayjs";
import ReportTestDelete from "../modal/ReportTestDelete";
import AutonomyOut from "../modal/ReportAutonomyOut";

// img
import arrow from "../img/arrow.svg";
import threeDot from "../img/3dot.svg";
import questionMark from "../img/Q.svg";
import TestSetting from "../modal/ReportTestSetting";
import { getCookie } from "../Cookie";
import jwtDecode from "jwt-decode";

const ReportUp = ({
  setModalOn,
  events,
  setLang,
  testId,
  reportLocation,
  hostUuid,
}) => {
  const token = getCookie("token");
  const isAdmin = jwtDecode(token).role === "admin";
  const navigate = useNavigate();
  const utc = new Date().getTimezoneOffset();
  const country = useRecoilValue(languageIs);
  // const [lang, setLang] = useState('kr');
  // const testId = useParams().testId;
  const { data: sum } = useGetReportAIJudge({ testId, hostUuid, isAdmin });
  // const { data: events } = useGetReportAIEvent({ testId, lang });

  // state
  const [checkCount, setCheckCount] = useState();
  const [autonomyOut, setAutonomyOut] = useState();
  const [reAccess, setReAccess] = useState();
  const [deleteButton, setDeleteButton] = useState(false);

  // modal
  const [testDeleteModal, setTestDeleteModal] = useState(false);
  const [autonomyOutModal, setAutonomyOutModal] = useState(false);
  const [testSettingModal, setTestSettingModal] = useState(false);

  useEffect(() => {
    if (country === "/ko") {
      setLang("kr");
      dayjs.locale("ko");
    } else {
      setLang("en");
      dayjs.locale("en");
    }
  }, [country]);

  useEffect(() => {
    if (sum?.judgeSummary?.isReActivateAroundSetting === true) {
      setReAccess("항상 진행");
    } else {
      setReAccess("진행 안함");
    }

    if (sum?.judgeSummary?.isTesterSelfExit === true) {
      setAutonomyOut("허용");
    } else {
      setAutonomyOut("허용 안함");
    }
  }, [sum]);

  useEffect(() => {
    if (events)
      setCheckCount(
        events?.testEventSummary?.userRequireConfirm?.notTaking +
          events?.testEventSummary?.userRequireConfirm?.analysisError,
      );
  }, [events]);

  return (
    <>
      {testDeleteModal && (
        <ReportTestDelete
          setTestDeleteModal={setTestDeleteModal}
          testId={testId}
        />
      )}
      {autonomyOutModal && (
        <AutonomyOut setAutonomyOutModal={setAutonomyOutModal} />
      )}
      {testSettingModal && (
        <TestSetting setTestSettingModal={setTestSettingModal} />
      )}
      <StBack>
        <div className="body">
          <div className="titleBox">
            <div className="title">
              <div className="imgPart">
                <img
                  className="arrow"
                  alt=""
                  onClick={() => {
                    //navigate(`/myexam`);
                    // 쿼리스트링 추가해서 뒤로가기 기능을 사용해야 함
                    // navigate(-1);
                    // report 안에서도 페이지네이션이 있어서 뒤로가기 변경
                    reportLocation
                      ? navigate(reportLocation)
                      : navigate(`/myexam`);
                  }}
                  src={arrow}
                />
              </div>
              {sum?.judgeSummary?.testName}
            </div>
            <div className="deleteIcon">
              <img
                src={threeDot}
                onClick={() => {
                  setDeleteButton(true);
                }}
                alt=""
              />
              {deleteButton && (
                <div>
                  <div
                    className="deleteButton"
                    onClick={() => {
                      setTestDeleteModal(true);
                      setDeleteButton(false);
                    }}
                  >
                    시험 삭제하기
                  </div>
                  <div
                    className="deleteBack"
                    onClick={() => {
                      setDeleteButton(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="midBox">
            <div className="midItem">
              <div className="middle">시험 날짜와 시간</div>
              <div className="midGrey">
                {dayjs(sum?.judgeSummary?.testStartTime).format(
                  "YYYY-MM-DD(ddd) A hh:mm",
                )}{" "}
                ~ {dayjs(sum?.judgeSummary?.testEndTime).format("A hh:mm")}{" "}
                (UTC+{-utc / 60})
              </div>
            </div>
            <div className="midItem">
              <div className="middle">응시 인원</div>
              <div className="midGrey">
                {sum?.judgeSummary?.testActualTester}명 응시 / 최대{" "}
                {sum?.judgeSummary?.testPlannedTester}명
              </div>
            </div>
            <div className="midItem">
              <div className="middle">
                응시자 자율 퇴장{" "}
                <img
                  src={questionMark}
                  onClick={() => setAutonomyOutModal(true)}
                  alt=""
                />
              </div>
              <div className="midGrey">{autonomyOut}</div>
            </div>
            <div className="midItem">
              <div className="middle">
                재접속 시 주변환경 재점검 진행{" "}
                <img
                  src={questionMark}
                  onClick={() => setTestSettingModal(true)}
                  alt=""
                />
              </div>
              <div className="midGrey">{reAccess}</div>
            </div>
            <div className="midItem thirdLine">
              <div className="middle">응원 메세지</div>
              <div className="midGrey">
                {sum?.judgeSummary?.testComment
                  ? sum?.judgeSummary?.testComment
                  : "-"}
              </div>
            </div>
          </div>
          <div className="middle">온라인 시험 AI 검증 요약</div>
          <div className="whiteBox">
            <div className="flexing">
              <div className="columning">
                <div className="inBox">
                  <div className="font13">업로드 된 영상 수</div>
                  <div className="font22">
                    {sum?.judgeSummary?.uploaded}
                    <span>개</span>
                  </div>
                </div>
                <div className="line" />
                <div className="line3" />
              </div>
              <div className="inBox">
                <div className="font13">진행 현황</div>
                <div className="font22">
                  {sum?.judgeSummary?.progress}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="gLine" />
            <div className="line" />
            <div className="inBox">
              <div className="font13">검증 완료 시간</div>
              <div className="font22">
                {sum?.judgeSummary?.testFinishTime === "-"
                  ? `${sum?.judgeSummary?.testFinishTime}`
                  : `${dayjs(sum?.judgeSummary?.testFinishTime).format(
                      "YYYY-MM-DD(ddd), A hh:mm",
                    )}`}
              </div>
            </div>
            <div className="line" />
            <div className="gLine" />
            <div className="inBox">
              <div className="font13">검증 결과보고서</div>
              <div className="font22">
                {sum?.judgeSummary?.step === "afterSendMail"
                  ? "분석 완료"
                  : "-"}
              </div>
            </div>
          </div>
          <div className="middle">응시자와 이벤트 요약</div>
          <div className="white2">
            <div className="leftWrapper">
              <div className="left">
                <div className="up">
                  <div className="confirm">총 응시자</div>
                  <div className="confirm">
                    총{" "}
                    <span>
                      {events &&
                        events?.testEventSummary?.userRequireConfirm?.cheat +
                          events?.testEventSummary?.userRequireConfirm
                            ?.abnormal +
                          events?.testEventSummary?.userRequireConfirm
                            ?.notTaking +
                          events?.testEventSummary?.userRequireConfirm
                            ?.analysisError +
                          events?.testEventSummary?.userRequireConfirm?.normal}
                    </span>{" "}
                    명
                  </div>
                </div>
                <div className="dive">
                  <div className="resultBox">
                    <div className="chartBox">
                      <div className="square" />
                      <div className="checks">
                        <div className="check"> 확인 요망</div>
                        <div className="check">
                          <span>
                            {
                              events?.testEventSummary?.userRequireConfirm
                                ?.cheat
                            }
                          </span>
                          &nbsp;명
                        </div>
                      </div>
                    </div>
                    <div className="chartBox">
                      <div className="blueBox" />
                      <div className="checks">
                        <div className="check"> 주의 요망</div>
                        <div className="check">
                          <span>
                            {
                              events?.testEventSummary?.userRequireConfirm
                                ?.abnormal
                            }
                          </span>
                          &nbsp;명
                        </div>
                      </div>
                    </div>
                    <div className="chartBox">
                      <div className="pinkBox" />
                      <div className="checks">
                        <div className="check"> 분석오류</div>
                        <div className="check">
                          <span>
                            {
                              events?.testEventSummary?.userRequireConfirm
                                ?.analysisError
                            }
                          </span>
                          &nbsp;명
                        </div>
                      </div>
                    </div>
                    <div className="chartBox">
                      <div className="greyBox" />
                      <div className="checks">
                        <div className="check"> 미완료</div>
                        <div className="check">
                          <span>
                            {
                              events?.testEventSummary?.userRequireConfirm
                                ?.notTaking
                            }
                          </span>
                          &nbsp;명
                        </div>
                      </div>
                    </div>
                    <div className="chartBox">
                      <div className="greenBox" />
                      <div className="checks">
                        <div className="check"> 정상 응시</div>
                        <div className="check">
                          <span>
                            {
                              events?.testEventSummary?.userRequireConfirm
                                ?.normal
                            }
                          </span>
                          &nbsp;명
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line2" />
              <div className="line4" />
              <div className="gLine" />
              <div className="left">
                <div className="up">
                  <div className="confirm">확인이 필요한 응시자</div>
                  <div className="confirm">
                    총{" "}
                    <span>
                      {events &&
                        events?.testEventSummary?.userRequireConfirm?.cheat +
                          events?.testEventSummary?.userRequireConfirm
                            ?.abnormal +
                          events?.testEventSummary?.userRequireConfirm
                            ?.notTaking +
                          events?.testEventSummary?.userRequireConfirm
                            ?.analysisError}
                    </span>{" "}
                    명
                  </div>
                </div>
                <div className="dive3">
                  {events && (
                    <DonutGraph
                      width={143}
                      height={126}
                      thick={40}
                      half={true}
                      data={events?.testEventSummary?.userRequireConfirm}
                      degree={270}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="gLine" />
            <div className="line2" />
            <div className="left">
              <div className="up">
                <div className="confirm">감지된 이벤트</div>
                <div className="confirm">
                  총{" "}
                  <span>
                    {events && events?.testEventSummary?.detectObj?.count}
                  </span>{" "}
                  건
                </div>
              </div>
              <div className="just">TOP 3 이벤트</div>
              {events?.testEventSummary?.detectObj?.count ? (
                <div className="dive2">
                  <div className="checking">
                    <div className="checked">
                      <div className="rounding" />
                      <div className="list">
                        {events?.testEventSummary?.detectObj?.first ? (
                          events?.testEventSummary?.detectObj?.first?.sort
                        ) : (
                          <div className="noEvent">감지된 이벤트 없음</div>
                        )}
                      </div>
                      <div className="checkk">
                        <span>
                          {events?.testEventSummary?.detectObj?.first ? (
                            events?.testEventSummary?.detectObj?.first?.count
                          ) : (
                            <>-&nbsp;</>
                          )}
                        </span>
                        &nbsp;건
                      </div>
                    </div>
                    <div className="checked">
                      <div className="rounding" />
                      <div className="list">
                        {events?.testEventSummary?.detectObj?.second ? (
                          events?.testEventSummary?.detectObj?.second?.sort
                        ) : (
                          <div className="noEvent">감지된 이벤트 없음</div>
                        )}
                      </div>
                      <div className="checkk">
                        <span>
                          {events?.testEventSummary?.detectObj?.second ? (
                            events?.testEventSummary?.detectObj?.second?.count
                          ) : (
                            <>-&nbsp;</>
                          )}
                        </span>
                        &nbsp;건
                      </div>
                    </div>
                    <div className="checked">
                      <div className="rounding" />
                      <div className="list">
                        {events?.testEventSummary?.detectObj?.third ? (
                          events?.testEventSummary?.detectObj?.third?.sort
                        ) : (
                          <div className="noEvent">감지된 이벤트 없음</div>
                        )}
                      </div>
                      <div className="checkk">
                        <span>
                          {events?.testEventSummary?.detectObj?.third ? (
                            events?.testEventSummary?.detectObj?.third?.count
                          ) : (
                            <>-&nbsp;</>
                          )}
                        </span>
                        &nbsp;건
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="nothing">감지된 이벤트가 없음</div>
              )}
            </div>
          </div>
        </div>
      </StBack>
    </>
  );
};

const StBack = styled.div`
  @media ${device.pc} {
    width: 100%;
    max-width: 100%;
    background-color: #f0f1f2;
    .onlyForMobile {
      display: none;
    }
    .noEvent {
      color: #bdbdbd;
    }
    .rounding {
      width: 5px;
      height: 5px;
      margin: auto 10px auto 0;
      border-radius: 5px;
      background-color: #55595f;
    }
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
    }
    .line3 {
      display: none;
    }
    .gLine {
      display: none;
    }
    .flexing {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }
    .columning {
      display: flex;
    }
    .body {
      width: 1228px;
      padding-top: 80px;
      margin: 0 auto 0 auto;
      background-color: #f0f1f2;
      .up {
      }
      .down {
        margin: 60px 0 15px 0;
      }
      .titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
        font-family: "Bold";
        .title {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          gap: 19px;
          line-height: 41px;
          .imgPart {
            display: flex;
            align-items: center;
            .arrow {
              width: 28px;
              height: 28px;
              cursor: pointer;
            }
          }
        }
        .deleteIcon {
          position: relative;
          .deleteButton {
            display: flex;
            align-items: center;
            width: 240px;
            height: 52px;
            font-size: 16px;
            font-family: "regular";
            line-height: 24px;
            position: absolute;
            z-index: 52;
            right: 0;
            top: 0;
            background: #ffffff;
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
            border-radius: 20.5px;
            padding: 14px 24px;
            box-sizing: border-box;
            cursor: pointer;
          }
          .deleteBack {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 51;
            cursor: default;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
    .midBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 36px;
      margin: 60px 0 60px 0;
      .thirdLine {
        grid-column: 1/3;
      }
    }
    .midGrey {
      color: #444444;
      margin: 10px 0 0 0;
      font-size: 18px;
      line-height: 27px;
    }
    .middle {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: "Medium";
      line-height: 27px;
      img {
        margin-left: 10px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .whiteBox {
      display: grid;
      grid-template-columns: repeat(6, auto);
      :nth-child(1) {
        /* grid-column: 1 / span 2; */
      }
      align-items: center;
      padding: 16px 0px;
      margin: 15px 0 60px;
      width: 1228px;
      /* height: 106px; */
      padding: 28px 0;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
    }
    .inBox {
      display: grid;
      flex-direction: column;
      /* grid-column: 1 / 2; */
      grid-template-rows: 1fr 1fr;
      justify-content: center;
      text-align: center;
      gap: 7px;
      align-items: center;
      width: 306.62px;
      /* height: 60px; */
      font-size: 13px;
    }
    .font13 {
      font-size: 13px;
      font-family: "Medium";
      line-height: 19px;
    }
    .font22 {
      font-size: 22px;
      font-family: "Medium";
      line-height: 33px;
      span {
        font-size: 16px;
      }
    }
    .line2 {
      width: 0.5px;
      height: 232px;
      background: #bdbdbd;
      margin: 0 23.75px;
    }
    .line {
      width: 0.5px;
      height: 59px;
      background-color: #bdbdbd;
    }
    .white2 {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      width: 1140px;
      height: 232px;
      padding: 27px 44px;
      margin-top: 15px;
      svg {
        height: 140px;
      }
      .leftWrapper {
        display: flex;
      }
      .line4 {
        display: none;
      }
      .left {
        display: flex;
        flex-direction: column;
        .up {
          display: flex;
          justify-content: space-between;
          width: 348px;
          height: 33px;
        }
        .confirm {
          height: 33px;
          font-size: 22px;
          font-family: "Medium";
          span {
            color: #2276dc;
          }
        }
        .dive {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 348px;
          /* margin : px 0 0 0; */
        }
        .dive2 {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          /* width: 526px; */
          width: 348px;
          margin: 0;
        }
        .dive3 {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 348px;
        }
        .pos {
          position: absolute;
          top: 60%;
          left: 21%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 144px;
          height: 117px;
        }
      }
      .num {
        font-size: 52px;
        font-family: "Medium";
      }
      .result {
        font-family: "Medium";
      }
      .resultBox {
        display: flex;
        flex-direction: column;
        margin-top: 3px;
      }
      .chartBox {
        display: flex;
        align-items: center;
        height: 24px;
        margin-top: 10px;
      }
      .checks {
        display: flex;
        justify-content: space-between;
        width: 318px;
        height: 24px;
      }
      .check {
        /* margin-left : auto; */
        color: #444444;
        span {
          color: #2276dc;
        }
      }
      .checkk {
        margin-left: auto;
        color: #444444;
      }
      .square {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        background-color: #c83b38;
      }
      .greenBox {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
      }
      .checked {
        display: flex;
        width: 348px;
        margin: 10px 0 0 0;
      }
      .blueBox {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        background-color: #ffbc00;
      }
      .greyBox {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        background-color: #bdbdbd;
      }
      .pinkBox {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        background-color: #e9b1af;
      }
      .greenBox {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        background-color: #3c9c9c;
      }
      .left {
        .up {
          .confirm {
            line-height: 33px;
          }
        }
        .just {
          margin-top: 33px;
          font-size: 18px;
          font-family: "Medium";
          line-height: 27px;
        }
        .dive {
          margin-top: 39px;
          .resultBox {
            display: grid;
            flex-direction: column;
            gap: 10px;
            .chartBox {
              line-height: 24px;
              margin: 0;
            }
          }
        }
        .dive2 {
          .checking {
            display: grid;
            flex-direction: column;
            gap: 10px;
            margin-top: 13px;
            .checked {
              margin: 0;
              .list {
                line-height: 24px;
              }
            }
          }
        }
        .dive3 {
          margin-top: 39px;
        }
      }
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px 20px;
    box-sizing: border-box;
    background-color: #f0f1f2;
    .noEvent {
      color: #bdbdbd;
    }
    .rounding {
      width: 3px;
      height: 3px;
      margin: auto 10px auto 0;
      border-radius: 1.5px;
      background-color: #55595f;
    }
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
      font-size: 14px;
    }
    .columning {
      display: flex;
    }
    .list {
      color: #55595f;
      width: 69%;
      text-indent: -20px;
      padding-left: 20px;
      font-size: 14px;
      line-height: 21px;
      font-family: "regular";
    }
    .flexing {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
    .line3 {
      width: 0.5px;
      height: 61px;
      background-color: #bdbdbd;
    }
    .body {
      width: 100%;
      margin: 0 auto 0 auto;
    }
    .up {
      width: 100%;
    }
    .down {
      margin: 60px 0 15px 0;
    }
    .titleBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 19px;
      line-height: 28px;
      padding: 24px 0 16px;
      font-family: "Bold";
      .title {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 12px;
        .imgPart {
          display: flex;
          align-items: center;
          height: 28px;
          margin-bottom: auto;
          .arrow {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
      .deleteIcon {
        position: relative;
        display: flex;
        align-items: center;
        height: 28px;
        margin-bottom: auto;
        margin-left: 10px;
        .deleteButton {
          display: flex;
          align-items: center;
          width: 168px;
          height: 52px;
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
          position: absolute;
          z-index: 52;
          right: 0;
          top: 0;
          background: #ffffff;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
          border-radius: 20.5px;
          padding: 14px 24px;
          box-sizing: border-box;
          cursor: pointer;
        }
        .deleteBack {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 51;
          cursor: default;
        }
        img {
          cursor: pointer;
        }
      }
    }
    .gLine {
      width: 100%;
      height: 0.5px;
      background: #d6d9dd;
      margin: 24px auto;
    }
    .midBox {
      padding: 14px 0 28px;
      .midItem {
        display: grid;
        flex-direction: column;
        padding: 14px 0;
        box-sizing: border-box;
        gap: 5px;
        .midGrey {
          line-height: 19px;
          font-size: 16px;
          font-family: "regular";
          padding: 6px 0;
        }
      }
    }
    .middle {
      display: flex;
      font-size: 13px;
      font-family: "regular";
      line-height: 16px;
      color: #55595f;
      img {
        width: 16px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
    .whiteBox {
      display: flex;
      flex-direction: column;
      padding: 24px 12px;
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      margin: 10px auto 54px auto;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
    }
    .inBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-size: 13px;
    }
    .font13 {
      font-size: 12px;
      font-family: "medium";
      line-height: 18px;
    }
    .font22 {
      font-size: 22px;
      margin-top: 7px;
      font-family: "medium";
      line-height: 33px;
      span {
        font-size: 14px;
      }
    }
    .line2 {
      display: none;
      width: 0.5px;
      height: 198px;
      background: #bdbdbd;
      margin: 0 43.75px;
    }
    .line {
      display: none;
    }
    .white2 {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      width: 100%;
      margin-top: 10px;
      padding: 24px;
      box-sizing: border-box;
      svg {
        height: 140px;
      }
      .leftWrapper {
        display: grid;
        grid-template-columns: 1fr 48px 1fr;
        align-items: center;
        .gLine {
          display: none;
        }
      }
      .line4 {
        width: 0.5px;
        height: 198px;
        background-color: #bdbdbd;
        margin: 0 23.75px;
      }
      .left {
        display: flex;
        flex-direction: column;
      }
      .up {
        display: flex;
        justify-content: space-between;
        line-height: 27px;
      }
      .confirm {
        height: 27px;
        font-size: 18px;
        font-family: "medium";
        span {
          color: #2276dc;
        }
      }
      .dive {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 36px;
      }
      .dive2 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
      }
      .dive3 {
        margin-top: 36px;
      }
      .pos {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 144px;
        height: 117px;
      }
    }
    .num {
      font-size: 52px;
      font-family: "Medium";
    }
    .result {
      font-family: "Medium";
    }
    .resultBox {
      display: grid;
      flex-direction: column;
      gap: 6px;
      /* margin-top: 24px; */
      /* width: 210px; */
      width: 88%;
      min-width: 210px;
      .chartBox {
        display: flex;
        align-items: center;
        .square {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          background-color: #c83b38;
        }
        .checks {
          display: flex;
          justify-content: space-between;
          /* width: 163px; */
          flex-grow: 1;
          .check {
            color: #444444;
            line-height: 21px;
            span {
              color: #2276dc;
            }
          }
        }
      }
    }
    .checkk {
      margin-left: auto;
      color: #444444;
    }
    .checking {
      width: 100%;
    }
    .greenBox {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
    }
    .just {
      margin-top: 36px;
      font-size: 15px;
      font-family: "Medium";
      line-height: 22px;
    }
    .checked {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0 0 0;
    }
    .blueBox {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
      background-color: #ffbc00;
    }
    .greyBox {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
      background-color: #bdbdbd;
    }
    .pinkBox {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
      background-color: #e9b1af;
    }
    .greenBox {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
      background-color: #3c9c9c;
    }
  }
  @media ${device.mobile} {
    .line4 {
      display: none;
    }
    .white2 {
      .leftWrapper {
        display: flex;
        flex-direction: column;
        .left {
          width: 100%;
        }
        .gLine {
          display: block;
        }
      }
    }
  }
`;

export const StTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
`;

export default ReportUp;
