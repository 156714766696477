import { UseQueryResult, useQuery } from "react-query";
import getApi from "../../api/getApi";
import { GetAdminInfo } from "../../interface/apiInterface";
import { getCookie } from "../../Cookie";
import jwtDecode from "jwt-decode";
import { DecodedAdminToken } from "../../interface/authInterface";

const useGetAdminInfo = ({
  token,
}: {
  token: string;
}): UseQueryResult<GetAdminInfo> => {
  const isAdmin = token
    ? (jwtDecode(token) as DecodedAdminToken)?.role === "admin"
    : false;

  const fetcher = async () => {
    const response = await getApi.getAdminInfo();
    return response.data;
  };
  return useQuery(["adminInfo", token], fetcher, {
    enabled: isAdmin,
  });
};

export default useGetAdminInfo;
