import { atom } from "recoil";

// 티켓ID 83 관련 추가
interface HoverToolTipProps {
  isHover: boolean;
  start: number;
  end: number;
  level: string | null;
  index: number;
}

// 티켓ID 83 관련 추가
const HoverToolTip = atom<HoverToolTipProps>({
  key: "HoverToolTip",
  default: {
    isHover: false,
    start: 0,
    end: 0,
    level: null,
    index: 0,
  },
});

// 티켓ID 83 관련 추가
interface SelectedToolTipProps {
  isSelected: boolean;
  start: number;
  end: number;
  level: string | null;
  index: number;
}

// 티켓ID 83 관련 추가
const SelectedToolTip = atom<SelectedToolTipProps>({
  key: "SelectedToolTip",
  default: {
    isSelected: false,
    start: 0,
    end: 0,
    level: null,
    index: 0,
  },
});

/** 티켓ID 77 관련 추가 */
const HoverToolTipModal = atom<HoverToolTipProps>({
  key: "HoverToolTipModal",
  default: {
    isHover: false,
    start: 0,
    end: 0,
    level: null,
    index: 0,
  },
});

/** 티켓ID 77 관련 추가 */
const SelectedToolTipModal = atom<SelectedToolTipProps>({
  key: "SelectedToolTipModal",
  default: {
    isSelected: false,
    start: 0,
    end: 0,
    level: null,
    index: 0,
  },
});

interface UserInfoProps {
  owner_email: string | null;
  owner_name: string | null;
  owner_uid: string | null;
  plan: string | null;
  count: number;
  hostEmail: string | null;
  hostName: string | null;
  hostUuid: string | null;
  hostPlan: string | null;
  hostTestCount: number;
  hostLanguage: string | null;
  hasMessage: boolean;
  hasInvite: number;
  hostPhoneNumber: string | null;
}

const userInfo = atom<UserInfoProps>(
  //토큰으로 넘어온 로그인한 유저 정보 저장
  {
    key: "userInfo",
    default: {
      owner_email: null,
      owner_name: null,
      owner_uid: null,
      plan: null,
      count: 0,
      hostEmail: null,
      hostName: null,
      hostUuid: null,
      hostPlan: null,
      hostTestCount: 0,
      hostLanguage: null,
      hasMessage: false,
      hasInvite: 0,
      hostPhoneNumber: null,
    },
  },
);

const toastOn = atom({
  key: "toastOn",
  default: false,
});

const toastMessage = atom({
  key: "toastMessage",
  default: "",
});

const modalPadding = atom({
  key: "modalPadding",
  default: "",
});

const bannerOn = atom({
  key: "bannerOn",
  default: true,
});

const countingStar = atom({
  key: "countingStar",
  default: 0,
});

const languageIs = atom({
  key: "languageIs",
  default: "/ko",
});

const userlang = atom({
  key: "userLanguageIs",
  default: "KR",
});

const marketAtom = atom({
  key: "marketIsReal",
  default: 0,
});

const loginModal = atom({
  key: "loginModal",
  default: false,
});

const uId = atom({
  key: "uid",
  default: 0,
});

const examDelete = atom({
  key: "examDelete",
  default: false,
});

const deleteScroll = atom({
  key: "deleteScroll",
  default: 0,
});

const clickInfo = atom({
  key: "clickInfo",
  default: {},
});

const userListScroll = atom({
  key: "scroll",
  default: 0,
});

const userEmail = atom({
  key: "userEmail",
  default: {
    authNumInputValue: null,
    certified: false,
  },
});

const examUpdate = atom({
  key: "updateExam",
  default: true,
});

const toastUpdate = atom({
  key: "updateToast",
  default: false,
});

/** 관리자 로그인 여부 */
const isAdminLogin = atom({
  key: "adminLogin",
  default: false,
});

export {
  HoverToolTip,
  SelectedToolTip,
  HoverToolTipModal,
  SelectedToolTipModal,
  userInfo,
  clickInfo,
  userEmail,
  examUpdate,
  examDelete,
  modalPadding,
  bannerOn,
  toastOn,
  toastMessage,
  deleteScroll,
  uId,
  loginModal,
  languageIs,
  userlang,
  userListScroll,
  marketAtom,
  countingStar,
  toastUpdate,
  isAdminLogin,
};
