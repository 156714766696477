import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface ButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  margin?: string;
  font_size?: number;
  font_color?: string;
  font_family?: string;
  border_radius?: number;
  border?: string;
  pcWidth?: string;
  pcHeight?: number;
  pcFontsize?: number;
  pcFontcolor?: string;
  pcFontfamily?: string;
  pcBorderRadius?: number;
  pcBorder?: string;
  pcMargin?: string;
  text?: string;
  placeholdercolor?: string;
  padding?: string;
  pcPadding?: string;
  backgroundcolor?: string;
  cursor?: string;
  disabled?: boolean;
  onclick?: () => void;
}

const OrangeButton: React.FC<ButtonProps> = (props) => {
  return (
    <StButton
      ref={props.buttonRef}
      type="submit"
      margin={props.margin}
      onClick={props.onclick}
      font_size={props.font_size}
      font_color={props.font_color}
      font_family={props.font_family}
      border={props.border}
      border_radius={props.border_radius}
      pcWidth={props.pcWidth}
      pcHeight={props.pcHeight}
      pcFontsize={props.pcFontsize}
      pcFontcolor={props.pcFontcolor}
      pcFontfamily={props.pcFontfamily}
      pcBorder={props.pcBorder}
      pcBorderRadius={props.pcBorderRadius}
      pcPadding={props.pcPadding}
      pcMargin={props.pcMargin}
      padding={props.padding}
      backgroundcolor={props.backgroundcolor}
      cursor={props.cursor}
      disabled={props.disabled}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  width: 204px;
  height: 42px;
  padding: 15px 0;
  font-size: ${(props) => (props.font_size ? props.font_size : "14")}px;
  font-family: "medium";
  color: white;
  border-radius: 43px;
  box-sizing: border-box;
  background-color: #ed893e;
  border: none;
  @media ${device.pc} {
    width: ${(props) => props.pcWidth};
    height: ${(props) => (props.pcHeight ? props.pcHeight : 52)}px;
    padding: ${(props) => props.pcPadding};
    font-size: ${(props) => (props.pcFontsize ? props.pcFontsize : "17")}px;
    font-family: ${(props) => props.pcFontfamily};
    margin: ${(props) => props.pcMargin};
    color: ${(props) => props.pcFontcolor};
    border: none;
  }
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};

  &:disabled {
    background-color: #f0f1f2;
    color: #d7d7d7;
    cursor: auto;
  }
`;

export default OrangeButton;
