import { useEffect, useState } from "react";
import styled from "styled-components";
import { getCookie } from "../Cookie";
import useGetFinishedExam from "../hooks/useGetFinishedExam";
import useGetAwait from "../hooks/useGetAwait";
import useGetDefaultExam from "../hooks/useGetDefaultExam";
import DeleteDenied from "../modal/MyExamTestDeleteDenied";
import jwtDecode from "jwt-decode";
import { examDelete } from "../atoms";

import TestInfo from "./TestInfo";
import { device } from "../hooks/device";

import { useRecoilValue } from "recoil";
import { examUpdate } from "../atoms";
import ModalFormD from "./ModalFormD";
import { Outlet } from "react-router-dom";
import { useOutletData } from "./OutletDataContext";
import ExamItemListBox from "../atomic/organism/ExamItemListBox";

const MyExamDown = ({ setMyPageScroll, setModalOn, hostUuid }) => {
  const [clickOne, setClickOne] = useState(false);
  const [clickTwo, setClickTwo] = useState(false);
  const [clickThree, setClickThree] = useState(false);
  const { setData } = useOutletData();

  const update = useRecoilValue(examUpdate);
  const updating = useRecoilValue(examDelete);
  const [testUid, setTestUid] = useState();

  // modal
  const [uploadModal, setUploadModal] = useState(false);
  const [videoAnalysis, setVideoAnalysis] = useState(false);
  const [deleteDeniedModal, setDeleteDeniedModal] = useState(false);

  const token = getCookie("token");
  const isAdmin = jwtDecode(token).role === "admin";

  const uid = hostUuid ? hostUuid : jwtDecode(token).hostUuid;
  const { data: finishing } = useGetFinishedExam(
    { uid, isAdmin },
    update,
    updating,
  );
  const finish = finishing?.getTestVerifiedList;
  const { data: beforeUploadd } = useGetDefaultExam(
    { uid, isAdmin },
    update,
    updating,
  );
  const beforeUpload = beforeUploadd?.getTestVerifyingList;
  const { data: waitingg } = useGetAwait({ uid, isAdmin });
  const waiting = waitingg?.getTestPending;

  const finishHeaderTitle = [
    { title: "시험명", flex: "1 0 0px" },
    { title: "날짜와 시간", flex: "0 0 180px" },
    { title: "업로드 된 영상", flex: "0 0 111px" },
    { title: "진행현황", flex: "0 0 98px" },
    { title: "검증완료 시간", flex: "0 0 160px" },
    { title: "검증결과 보고서", flex: "0 0 120px" },
    { title: "", flex: "0 0 51px" },
  ];

  const beforeUploadHeaderTitle = [
    { title: "시험명", flex: "1 0 0px" },
    { title: "날짜와 시간", flex: "0 0 180px" },
    { title: "업로드 된 영상", flex: "0 0 111px" },
    { title: "진행현황", flex: "0 0 112px" },
    { title: "예상완료 시간", flex: "0 0 160px" },
    { title: "검증결과 보고서", flex: "0 0 120px" },
  ];

  const waitingHeaderTitle = [
    { title: "시험명", flex: "1 0 0px" },
    { title: "날짜와 시간", flex: "0 0 180px" },
    { title: "업로드 된 영상", flex: "0 0 111px" },
    { title: "진행현황", flex: "0 0 98px" },
    { title: "예상완료 시간", flex: "0 0 160px" },
    { title: "검증결과 보고서", flex: "0 0 120px" },
    { title: "", flex: "0 0 51px" },
  ];

  const pageListCount = 10;

  useEffect(() => {
    setData({ testUid });
  }, [testUid]);

  return (
    <>
      {uploadModal && (
        <ModalFormD
          title="업로드 진행 시간"
          textOne="응시 영상 업로드가 진행 중 이에요."
          textTwo="업로드와 검증이 모두 끝날 때까지 기다려주세요."
          close={setUploadModal}
        />
      )}
      {videoAnalysis && (
        <ModalFormD
          title="AI 영상분석 중..."
          textOne="업로드 된 응시 영상들을 검증 중에 있어요."
          textTwo="검증이 끝날 때까지 기다려주세요."
          close={setVideoAnalysis}
        />
      )}
      {deleteDeniedModal && (
        <DeleteDenied
          setMyPageScroll={setMyPageScroll}
          setDeleteDeniedModal={setDeleteDeniedModal}
        />
      )}
      <StBody>
        <div className="in">
          {/* <div className="headBox">
            <div className="head">
              <div className="rotate">내 온라인 시험장</div>
            </div>
          </div> */}
          <div className="box">
            <ExamItemListBox
              listTitle="검증 완료"
              nothingMessage="완료된 시험이 없음"
              headerTextArr={finishHeaderTitle}
              listCount={finish?.length === undefined ? 0 : finish.length}
              // listCount={testListCount}
              header_background_color="#F0F1F2"
              Box_width="93vw"
              pageListCount={pageListCount}
              testType="verified"
            >
              {finish?.length !== 0 && (
                <>
                  {finish?.map((value, index) => (
                    <TestInfo
                      key={index}
                      setUploadModal={setUploadModal}
                      setVideoAnalysis={setVideoAnalysis}
                      testStartTime={value.testStartTime}
                      testEndTime={value.testEndTime}
                      testName={value.testName}
                      testStep={value.testStep}
                      testUuid={value?.testUuid}
                      setTestUid={setTestUid}
                      finishTime={value?.expectFinishTime}
                      analysisTime={value?.testFinishTime}
                      setModalOn={setModalOn}
                      testUploaded={value.testUploaded}
                      setDeleteDeniedModal={setDeleteDeniedModal}
                      testType="verified"
                      testIndex={index}
                      // setTestListCount={setTestListCount}
                      pageListCount={pageListCount}
                    />
                  ))}
                </>
              )}
            </ExamItemListBox>
            <ExamItemListBox
              listTitle="검증 / 업로드 진행 중"
              nothingMessage="진행 중인 시험이 없음"
              headerTextArr={beforeUploadHeaderTitle}
              listCount={
                beforeUpload?.length === undefined ? 0 : beforeUpload.length
              }
              header_background_color="#F0F1F2"
              title_margin_top="44px 0 0 0"
              Box_width="93vw"
              testType="verifying"
            >
              {beforeUpload?.length !== 0 && (
                <>
                  {beforeUpload?.map((value, index) => (
                    <TestInfo
                      key={index}
                      setUploadModal={setUploadModal}
                      setVideoAnalysis={setVideoAnalysis}
                      testStartTime={value.testStartTime}
                      testEndTime={value.testEndTime}
                      testName={value.testName}
                      testStep={value.testStep}
                      testUuid={value?.testUuid}
                      setTestUid={setTestUid}
                      testVerifying={value?.testVerifying}
                      finishTime={value?.expectFinishTime}
                      analysisTime={value?.testFinishTime}
                      testUploaded={value.testUploaded}
                      setModalOn={setModalOn}
                      setDeleteDeniedModal={setDeleteDeniedModal}
                      testType="verifying"
                      testIndex={index}
                      pageListCount={pageListCount}
                    />
                  ))}
                </>
              )}
            </ExamItemListBox>
            <ExamItemListBox
              listTitle={
                waiting?.length === 0 || waiting?.length === undefined
                  ? "업로드 시작 전"
                  : "진행 예정"
              }
              nothingMessage="예정된 시험이 없음"
              headerTextArr={waitingHeaderTitle}
              listCount={waiting?.length === undefined ? 0 : waiting.length}
              header_background_color="#F0F1F2"
              title_margin_top="44px 0 0 0"
              Box_width="93vw"
              testType="pending"
            >
              {waiting?.length !== 0 && (
                <>
                  {waiting?.map((value, index) => (
                    <TestInfo
                      key={index}
                      setUploadModal={setUploadModal}
                      setVideoAnalysis={setVideoAnalysis}
                      testStartTime={value.testStartTime}
                      testEndTime={value.testEndTime}
                      testName={value.testName}
                      testStep={value.testStep}
                      testUuid={value?.testUuid}
                      setTestUid={setTestUid}
                      finishTime={value?.expectFinishTime}
                      analysisTime={value?.testFinishTime}
                      testUploaded={value.testUploaded}
                      setModalOn={setModalOn}
                      hostType={value.hostType}
                      setDeleteDeniedModal={setDeleteDeniedModal}
                      testType="pending"
                      testIndex={index}
                      setMyPageScroll={setMyPageScroll}
                      pageListCount={pageListCount}
                    />
                  ))}
                </>
              )}
            </ExamItemListBox>
          </div>
        </div>
      </StBody>
      <Outlet context={testUid} />
    </>
  );
};

const StBody = styled.div`
  @media ${device.tabMob} {
    width: 100%;
    max-width: 752px;
    margin: 0 auto 20px auto;
    padding: 0 24px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .onlyForPc {
      display: none !important;
    }
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
      font-size: 14px;
    }
    .in {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 4px;
    }
    .boxs {
      margin-top: 16px;
    }
    .titleBox {
      display: flex;
      align-items: center;
    }
    .titleBox2 {
      display: flex;
      align-items: center;
      margin-top: 44px;
    }
    .titleBox3 {
      display: flex;
      align-items: center;
      margin-top: 44px;
    }
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11px;
      height: 11px;
      margin-left: 10px;
      cursor: pointer;
    }
    .title {
      font-size: 18px;
      line-height: 21px;
      font-family: "Bold";
    }
    .box {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      background-color: #f0f1f2;
      /* border-radius: 0px 12px 12px 12px; */
      border-radius: 12px;
    }
    .headBox {
      width: 300px;
      overflow: hidden;
      border-radius: 10px 0px 0 0;
    }
    .rotate {
      font-size: 12.5px;
      line-height: 15px;
      color: #55595f;
      margin-left: 10px;
      transform: skew(-30deg);
      margin-top: 13.5px;
    }
    .head {
      display: flex;
      justify-content: center;
      text-align: center;
      /* align-items: center; */
      /* line-height: 50px; */
      width: 190px;
      height: 34px;
      border-radius: 10px 10px 0 0;
      background: #f0f1f2;
      transform: skew(30deg) translate(-30px, 0px);
    }
  }
  @media ${device.tablet} {
    .boxs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
  @media ${device.mobile} {
    .boxs {
      display: grid;
      /* flex-direction: column; */
      gap: 16px;
    }
  }
  @media ${device.pc} {
    /* Noah */
    /* width: 1228px; */
    /* margin: 60px auto 80px auto; */
    margin: 60px 0 80px 0;
    .completeTitle {
      display: flex;
      font-family: "Medium";
      font-size: 14px;
      background-color: #f0f1f2;
      padding: 16px 8px;
      text-align: center;
      .flex1 {
        flex-grow: 1;
        flex-basis: 0px;
      }
      .flex2 {
        flex-basis: 180px;
      }
      .flex3 {
        flex-basis: 111px;
      }
      .flex4 {
        flex-basis: 98px;
      }
      .flex5 {
        flex-basis: 160px;
      }
      .flex6 {
        flex-basis: 120px;
      }
      .flex7 {
        flex-basis: 51px;
      }
    }
    .onlyForMobile {
      display: none !important;
    }
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
      font-size: 18px;
    }
    /* Noah */
    .completeBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1228px;
      /* max-width: 1228px; */
      width: 93vw;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 24px;
      padding: 20px;
    }
    .boxs {
      /* Noah */
      /* display: grid; */
      /* grid-template-columns: 1fr 1fr 1fr; */
      /* gap: 19px; */
      display: flex;
      flex-direction: column;
      /* width: 1188px; */
      width: 100%;
      margin-top: 24px;
    }
    .titleBox2,
    .titleBox3,
    .titleBox {
      /* Noah */
      display: flex;
      align-items: center;
      justify-content: start;
      width: 100%;
      line-height: 33px;
      box-sizing: border-box;
      padding: 12px 12px 20px 12px;
    }
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 15px;
      cursor: pointer;
    }
    .title {
      font-family: "Bold";
      font-size: 22px;
    }
    .body {
      width: 1118px;
      padding: 55px;
      background-color: #fafafa;
    }
    .box {
      /* Noah */
      /* display: grid; */
      display: flex;
      flex-direction: column;
      gap: 60px;
      /* width: 1118px; */
      width: calc(100vw - 10px);
      align-items: center;
      /* padding: 55px; */
      padding: 80px 0;
      background-color: #f0f1f2;
      /* border-top-right-radius: 20px; */
      /* border-bottom-left-radius: 20px; */
      /* border-bottom-right-radius: 20px; */
    }
    .headBox {
      width: 300px;
      overflow: hidden;
      border-radius: 10px 0px 0 0;
    }
    .rotate {
      color: #242424;
      margin-left: 20px;
      transform: skew(-30deg);
    }
    .head {
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 50px;
      width: 244px;
      height: 50px;
      border-radius: 10px 10px 0 0;
      background: #f0f1f2;
      transform: skew(30deg) translate(-30px, 0px);
    }
  }
`;

export const StImg = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
  cursor: pointer;
`;

export const StTitleBox = styled.div`
  display: flex;
  align-items: center;
  .down {
    margin-top: 55px;
  }
`;

export default MyExamDown;
