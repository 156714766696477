import MyExamDown from "../components/MyExamDown";
import MyExamUp from "../components/MyExamUp";
import styled from "styled-components";

// styled components

// components
import InviteModal from "../components/InviteModal";

// modals

//img

const MyExam = ({ setMyPageScroll, setModalOn }) => {
  const { hostUuid, hostEmail, hostName } =
    JSON.parse(localStorage.getItem("newTabData")) || {};

  return (
    <>
      <InviteModal />
      <StMyExam>
        <MyExamUp
          hostUuid={hostUuid}
          hostEmail={hostEmail}
          hostName={hostName}
        />
        <MyExamDown
          setModalOn={setModalOn}
          setMyPageScroll={setMyPageScroll}
          hostUuid={hostUuid}
        />
      </StMyExam>
    </>
  );
};

const StMyExam = styled.div`
  min-height: calc(100vh - 353px);
`;

export default MyExam;
