import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import WeekSmallButton from "../atomic/molecule/Button/WeekSmallButton";
import NegativeButton from "../atomic/molecule/Button/NegativeButton";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

const EventTable = ({
  code,
  sort,
  count,
  level,
  duration,
  index,
  eventListButton,
  isEdit,
  setIsDelList,
  codeCount,
  setCodeCount,
  addEditID,
  removeEditID,
}) => {
  const [circleColor, setCircleColor] = useState();
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (level === "cheat") {
      setCircleColor("#C83B38");
    } else if (level === "abnormal") {
      setCircleColor("#FFBC00");
    }
  }, [level]);

  useEffect(() => {
    if (codeCount[code] === count) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [codeCount]);

  if ((index > 2) & !eventListButton) {
    return;
  } else
    return (
      <StEventTable color={circleColor} isDim={isDisable}>
        <div className="tableSort">
          <div className="tableLevel" />
          <div className="tableText">{sort}</div>
          {isEdit && (
            <>
              {isDisable ? (
                <DivTwoPartImprove display="flex" flex_direction="row-reverse">
                  <WeekSmallButton
                    text="복원"
                    border={true}
                    height="24px"
                    onClick={() => {
                      setIsDelList((prev) => {
                        const updateList = prev.map((value) => {
                          if (value.code === code) {
                            removeEditID(value.id);
                            value.firstState && addEditID(value.id);
                            return { ...value, isDisable: false };
                          }
                          // value.code === code
                          //   ? { code: value.code, isDisable: false }
                          //   : value;
                          return value;
                        });

                        return updateList;
                      });
                      setCodeCount((prev) => ({
                        ...prev,
                        [code]: 0,
                      }));
                    }}
                  />
                </DivTwoPartImprove>
              ) : (
                <DivTwoPartImprove display="flex" flex_direction="row-reverse">
                  <NegativeButton
                    text="삭제"
                    border={true}
                    height="24px"
                    onClick={() => {
                      setIsDelList((prev) => {
                        const updateList = prev.map((value) => {
                          if (value.code === code) {
                            addEditID(value.id);
                            value.firstState && removeEditID(value.id);
                            return { ...value, isDisable: true };
                          }
                          // value.code === code
                          //   ? { code: value.code, isDisable: true }
                          //   : value;
                          return value;
                        });

                        return updateList;
                      });
                      setCodeCount((prev) => ({
                        ...prev,
                        [code]: count,
                      }));
                    }}
                  />
                </DivTwoPartImprove>
              )}
            </>
          )}
        </div>
        <div className="tableAccCount">{count}</div>
        <div className="tableAccDuration">{Math.floor(duration)} 초</div>
      </StEventTable>
    );
};

const StEventTable = styled.div`
  @media ${device.pc} {
    grid-template-columns: 522px 100px 100px;
    height: 40px;
    .tableSort {
      padding: 8px 20px;
      .tableLevel {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
  }
  @media ${device.tabMob} {
    grid-template-columns: 62% 1fr 1fr;
    min-height: 35px;
    font-size: 13px;
    line-height: 19px;
    .tableSort {
      padding: 6px 14px;
      word-break: break-all;
      .tableText {
        flex-grow: 1;
      }
      .tableLevel {
        min-width: 5px;
        height: 5px;
        border-radius: 5px;
      }
    }
  }
  display: grid;
  align-items: center;
  .tableSort {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    gap: 10px;
    .tableLevel {
      background-color: ${(props) => props.color};
      box-sizing: border-box;
      opacity: ${(props) => (props.isDim ? "0.2" : "1")};
    }
  }
  .tableText {
    opacity: ${(props) => (props.isDim ? "0.2" : "1")};
  }
  .tableAccCount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-left: 1px solid #d6d9dd;
    border-right: 1px solid #d6d9dd;
    text-align: center;
  }
  .tableAccDuration {
    text-align: center;
  }
`;

export default EventTable;
