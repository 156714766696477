import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import apis from "../api/getApi";
import { examUpdate } from "../atoms";

const useGetAwait = ({ uid, isAdmin }) => {
  // const utc = new Date().getTimezoneOffset();
  const update = useRecoilValue(examUpdate);

  const fetcher = async () => {
    const { data } = isAdmin
      ? await apis.getAwaitExamAdmin({ uid })
      : await apis.getAwaitExam({ uid });
    return data;
  };
  return useQuery(["await", update, isAdmin, uid], fetcher);
};

export default useGetAwait;
