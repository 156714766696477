import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportAIJudge = ({ testId, hostUuid, isAdmin }) => {
  const fetcher = async () => {
    const { data } = isAdmin
      ? await apis.getReportAIJudgeAdmin({ testUid: testId, hostUid: hostUuid })
      : await apis.getReportAIJudge({ uid: testId });
    return data;
  };
  return useQuery("reportListSum", fetcher);
};

export default useGetReportAIJudge;
