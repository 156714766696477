import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import jwtDecode from "jwt-decode";
import { countingStar, isAdminLogin, languageIs, userInfo } from "../atoms";
import { useRecoilValue } from "recoil";

// styled components

// components

// modals

// hooks
import useGetMyExam from "../hooks/useGetMyExam";

//img
import arrow from "../img/arrow.svg";
import { getCookie } from "../Cookie";
import { device } from "../hooks/device";
import { useSetRecoilState } from "recoil";

const MyExamUp = ({ hostUuid, hostName, hostEmail }) => {
  const token = getCookie("token");
  const country = useRecoilValue(languageIs);

  const navigate = useNavigate();
  const uid = hostUuid ? hostUuid : jwtDecode(token).hostUuid;
  const userData = useRecoilValue(userInfo);
  const isAdmin = jwtDecode(token).role === "admin";
  const { data: summary } = useGetMyExam({ uid, isAdmin });
  const data = summary?.getTestSummary;

  const [hostRegion, setHostRegion] = useState();
  const [hostPlan, setHostPlan] = useState();

  const setCounting = useSetRecoilState(countingStar);

  // mobile header 내 시험관리 counting 용으로 사용됨 지우지 말 것
  useEffect(() => {
    setCounting(data?.count);
  }, [data, setCounting]);

  useEffect(() => {
    if (data?.hostRegion === "KR") {
      setHostRegion("아시아");
    } else {
      setHostRegion("글로벌");
    }

    if (data?.hostPlan === "individualPlan") {
      setHostPlan("개인용");
    } else if (data?.hostPlan === "memberPlan") {
      setHostPlan("기업용 (구성원)");
    } else if (data?.hostPlan === "managerPlan") {
      setHostPlan("기업용 (관리자)");
    }
  }, [data]);

  return (
    <>
      <StBody>
        <div className="inBox">
          <div className="titleBox">
            <img
              alt=""
              className="arrow"
              onClick={() => {
                navigate(`/`);
              }}
              src={arrow}
            />
            내 시험 관리
          </div>
          <div className="st36">안녕하세요 :)</div>
          <div className="space">
            <div className="examInfo">
              <div className="line">
                <div className="infoLeft">주최자 정보 </div>
                <div className="infoBlack">
                  {" "}
                  {hostName ? hostName : userData.hostName} (
                  {hostEmail ? hostEmail : userData.hostEmail})
                  {/* {userData.hostName} ({userData.hostEmail}) */}
                </div>
              </div>
              <div className="line">
                <div className="infoLeft">서비스 플랜 </div>
                <div className="infoBlue">{hostPlan}</div>
              </div>
              {/* <div className="line">
                <div className="infoLeft">서비스 지역 </div>
                <div className="infoBlack">{hostRegion}</div>
              </div> */}
            </div>
            <div className="processBox">
              <div className="processIn">
                <div className="processUp">전체 검증 현황</div>
                <div className="processDown">{data?.situation}</div>
              </div>
              <div className="flexing">
                <div className="columnLine" />
                <div className="processIn">
                  <div className="processUp">검증 완료</div>
                  <div className="processDown">{data?.success}</div>
                </div>
                <div className="columnLine" />
              </div>
              <div className="processIn">
                <div className="processUp">진행 중</div>
                <div className="processDown">{data?.pending}</div>
              </div>
            </div>
          </div>
        </div>
      </StBody>
    </>
  );
};

const StBody = styled.div`
  @media ${device.tabMob} {
    display: flex;
    width: 100%;
    max-width: 752px;
    margin: 0 auto 0 auto;
    .flexing {
      display: flex;
      align-items: center;
    }
    .space {
      display: flex;
      flex-direction: column;
    }
    .line {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* min-width : 332px; */
      min-height: 55px;
      padding: 14px 0px;
    }
    .titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .columnLine {
      width: 1px;
      height: 58px;
      background-color: #d6d4cf;
    }
    .processUp {
      display: flex;
      justify-content: center;
      font-size: 12px;
      line-height: 18px;
      font-family: "Medium";
    }
    .processDown {
      display: flex;
      justify-content: center;
      font-size: 22px;
      font-family: "medium";
      line-height: 33px;
    }
    .processIn {
      display: grid;
      flex-direction: column;
      gap: 7px;
      width: 100%;
      /* height : 64px; */
    }
    .processBox {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 100%;
      height: 106px;
      border: 1px solid #d6d4cf;
      border-radius: 12px;
      padding: 24px 0;
      box-sizing: border-box;
      margin: 44px auto 0;
    }
    .st36 {
      font-size: 26px;
      margin-bottom: 44px;

      font-family: "Medium";
    }
    .st28 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "bold";
      font-size: 19px;
      margin-bottom: 30px;
      margin-top: 8px;
      padding: 16px 0px;
    }
    .infoBlack {
      /* height : 36px; */
      font-family: "Medium";
      margin: 11px 0 6px;
      font-size: 16px;
      line-height: 24px;
    }
    .infoBlue {
      font-family: "Medium";
      margin: 11px 0 6px;
      font-size: 16px;
      color: #2276dc;
      line-height: 24px;
    }
    .infoLeft {
      width: 130px;
      font-family: "Regular";
      font-size: 13px;
      line-height: 19px;
      color: #55595f;
    }
    .examInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .inBox {
      width: 100%;
      padding: 24px 24px 28px;
      box-sizing: border-box;
    }
    .titleBox {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 12px;
      line-height: 28px;
      font-size: 19px;
      font-family: "bold";
      margin-bottom: 46px;
      .arrow {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }
  @media ${device.pc} {
    display: flex;
    /* width: 1228px; */
    /* max-width: 1228px; */
    width: calc(100vw - 10px);
    box-sizing: border-box;
    /* margin: 0 auto 0 auto; */
    justify-content: center;
    padding-top: 80px;
    .flexing {
      display: flex;
    }
    .titleBox {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 19px;
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      margin-bottom: 60px;
      img {
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }
    .space {
      display: flex;
      justify-content: space-between;
    }
    .line {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 27px;
    }
    .titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .columnLine {
      width: 0.5px;
      height: 59px;
      background-color: #bdbdbd;
    }
    .processUp {
      display: flex;
      justify-content: center;
      font-size: 13px;
      line-height: 19px;
      font-family: "Medium";
    }
    .processDown {
      display: flex;
      justify-content: center;
      font-size: 22px;
      line-height: 33px;

      font-family: "Medium";
    }
    .processIn {
      display: grid;
      flex-direction: column;
      margin: auto;
      gap: 7px;
    }
    .processBox {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      width: 524px;
      /* height: 106px; */
      padding: 28px 0;
      box-sizing: border-box;
      border: 1px solid #d6d4cf;
      box-sizing: border-box;
      border-radius: 12px;
    }
    .st36 {
      font-size: 35px;
      margin: 0 0 50px 0;
      font-family: "Medium";
      line-height: 52px;
    }
    .st28 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 180px;
      font-size: 28px;
      font-family: "Bold";
      margin: 0 0 60px 0;
    }
    .infoBlack {
      font-size: 18px;
      margin: 0 0 0 117px;
    }
    .infoBlue {
      font-size: 18px;
      margin: 0 0 0 117px;
      color: #2276dc;
    }
    .infoLeft {
      /* width : 130px; */
      font-family: "Medium";
      font-size: 18px;
      /* margin-bottom: auto; */
    }
    .examInfo {
      display: grid;
      line-height: 27px;
      grid-template-rows: 27px 27px;
      /* justify-content: space-between; */
      gap: 12.5px;
      /* min-height: 104px; */
    }
    .inBox {
      /* width: 1400px; */
      max-width: 1228px;
      width: 93vw;
    }
  }
`;

export const St28px = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  font-size: 19px;
  margin: 0 0 60px 0;
`;

export default MyExamUp;
