import { useQuery } from "react-query";
import apis from "../api/getApi";
import { examUpdate } from "../atoms";
import { useRecoilValue } from "recoil";

const useGetMyExam = ({ uid, isAdmin }) => {
  const update = useRecoilValue(examUpdate);

  const fetcher = async () => {
    // const utc = new Date().getTimezoneOffset();
    const { data } = isAdmin
      ? await apis.getMyExamAdmin({ uid })
      : await apis.getMyExam({ uid });
    return data;
  };
  return useQuery(["exams", uid, isAdmin, update], fetcher);
};

export default useGetMyExam;
