import React, { useState } from "react";
import ReportDown from "../components/ReportDown";
import { Outlet, useParams } from "react-router-dom";

// styled components

// components
import ReportUp from "../components/ReportUp";
import styled from "styled-components";
import { device } from "../hooks/device";
import useGetReportAIEvent from "../hooks/useGetReportAIEvent";
import ReportNoticePopup from "../modal/ReportNoticePopup";
import { getCookie } from "../Cookie";
import { useRecoilValue } from "recoil";
import { isAdminLogin } from "../atoms";
import jwtDecode from "jwt-decode";
// pages

// modals

// img

const Report = ({ setModalOn, reportLocation }) => {
  const token = getCookie("token");
  const isAdmin = jwtDecode(token).role === "admin";
  const [lang, setLang] = useState("kr");
  const testId = useParams().testId;
  const { hostUuid } = JSON.parse(localStorage.getItem("newTabData")) || {};
  const { data: events } = useGetReportAIEvent({
    testId,
    hostUuid,
    isAdmin,
    lang,
  });
  const [reportNotice, setReportNotice] = useState(() => {
    if (isAdmin) return false;
    return getCookie("reportNoticePopUp") ? false : true;
  });

  return (
    <>
      {reportNotice && <ReportNoticePopup setReportNotice={setReportNotice} />}
      <StReport>
        <div className="wrapper">
          <ReportUp
            setModalOn={setModalOn}
            events={events}
            setLang={setLang}
            testId={testId}
            reportLocation={reportLocation}
            hostUuid={hostUuid}
          />
          <ReportDown events={events} hostUuid={hostUuid} />
        </div>
      </StReport>
      <Outlet />
    </>
  );
};

const StReport = styled.div`
  @media ${device.tabMob} {
    width: 100%;
    margin: 0 auto;
    background-color: #f0f1f2;
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
  }
  @media ${device.pc} {
    width: 100%;
  }
`;

export default Report;
