import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import Pagination from "../molecule/Pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { userInfo } from "../../atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { hostname } from "os";

interface ListBoxProps {
  listCount: number;
  pageListCount: number;
  testType: string;
  lastPage: number;
  hosts?: {
    hostUuid: string;
    hostName: string;
    hostEmail: string;
    hostType: "enterprise" | "individual";
    hostGroupName: string | null;
    verifyingCount: number;
    pendingCount: number;
    verifiedCount: number;
  }[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const AdminUserListBox: React.FC<ListBoxProps> = (props) => {
  const navigate = useNavigate();

  const header = ["No.", "기업명", "검증완료", "진행예정", "검증 진행중"];

  // const list = ["1", "이민지(코드프레소)", "20", "2", "2"];
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageArr, setPageArr] = useState<number[]>([]);
  const [pageStart, setPageStart] = useState<number>(1);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const setUserData = useSetRecoilState(userInfo);

  useEffect(() => {
    if (searchParams.has(props.testType)) {
      props.setCurrentPage(Number(searchParams.get(props.testType) as string));
    } else {
      props.setCurrentPage(1);
    }
  }, [location]);

  // const lastPage: number = useMemo(() => {
  //   return calLastPage(props.listCount, props.pageListCount);
  // }, [props.listCount]);

  const updatePageArr = useCallback(() => {
    const newPageArr = [];
    for (let i = pageStart; i <= pageStart + 9 && i <= props.lastPage; i++) {
      newPageArr.push(i);
    }
    setPageArr(newPageArr);
  }, [pageStart, props.lastPage]);

  useEffect(() => {
    let start = Math.floor((props.currentPage - 1) / 10) * 10 + 1;
    if (start !== pageStart) {
      setPageStart(start);
    }
  }, [props.currentPage, props.lastPage]);

  useEffect(() => {
    if (props.lastPage !== 0 && props.lastPage < props.currentPage) {
      props.setCurrentPage(props.lastPage);
    }
    updatePageArr();
  }, [pageStart, props.listCount]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("newTabData");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <DivTwoPartImprove
      display="flex"
      flex_direction="column"
      gap={10}
      border_radius={24}
      padding="20px 0px"
      background_color="#FFF"
      box_shadow="0px 1px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 8px 0px rgba(0, 0, 0, 0.14);"
      width="1228px"
      align_items="center"
      justify="center"
    >
      <DivTwoPartImprove
        display="flex"
        flex_direction="column"
        padding="0px 20px"
      >
        {/* 리스트 헤더 */}
        <DivTwoPartImprove
          display="grid"
          grid_template_column="100px 660px 140px 140px 140px"
        >
          {header.map((value, index) => (
            <DivTwoPartImprove
              key={index}
              display="flex"
              padding="16px 8px"
              justify="center"
              align_items="center"
              border_bottom="1px solid #BBB"
            >
              {value}
            </DivTwoPartImprove>
          ))}
        </DivTwoPartImprove>

        {/* {list.map((value, index) => (
          <DivTwoPartImprove
            key={index}
            display="flex"
            padding="16px 8px"
            justify="center"
            align_items="center"
            border_bottom="1px solid #F0F1F2"
          >
            {value}
          </DivTwoPartImprove>
        ))} */}
        {/* 리스트 메인 */}
        {props.hosts?.map((value, index) => (
          <>
            <DivTwoPartImprove
              display="grid"
              grid_template_column="100px 660px 140px 140px 140px"
              pc_hover_background_color="rgba(34, 118, 220, 0.05)"
              cursor="pointer"
              border_bottom="1px solid #F0F1F2"
              onclick={() => {
                // navigate(`/myexam`, { state: { hostUuid: value.hostUuid } });
                const data = {
                  hostUuid: value.hostUuid,
                  hostName: value.hostName,
                  hostEmail: value.hostEmail,
                };
                localStorage.setItem("newTabData", JSON.stringify(data));
                window.open(`/myexam`);
              }}
            >
              <DivTwoPartImprove
                display="flex"
                padding="16px 8px"
                justify="center"
                align_items="center"
              >
                {index + 1}
              </DivTwoPartImprove>
              <DivTwoPartImprove
                display="flex"
                padding="16px 8px"
                justify="center"
                align_items="center"
              >
                {value.hostName}{" "}
                {value.hostType === "enterprise"
                  ? `(${value.hostGroupName})`
                  : ""}
              </DivTwoPartImprove>
              <DivTwoPartImprove
                display="flex"
                padding="16px 8px"
                justify="center"
                align_items="center"
              >
                {value.verifiedCount}
              </DivTwoPartImprove>
              <DivTwoPartImprove
                display="flex"
                padding="16px 8px"
                justify="center"
                align_items="center"
              >
                {value.verifyingCount}
              </DivTwoPartImprove>
              <DivTwoPartImprove
                display="flex"
                padding="16px 8px"
                justify="center"
                align_items="center"
              >
                {value.pendingCount}
              </DivTwoPartImprove>
            </DivTwoPartImprove>
          </>
        ))}
      </DivTwoPartImprove>
      {/* 페이지네이션 */}
      <Pagination
        pc_padding="24px 16px"
        pc_gap={10}
        lastPage={props.lastPage}
        pageArr={pageArr}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        queryType={props.testType}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </DivTwoPartImprove>
  );
};

export default AdminUserListBox;
