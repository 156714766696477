import axios from "axios";
import customAxios from "./handler";
import { CommentEditRequest } from "../interface/apiInterface";

const patchApi = {
  memberWithdrawal: (hostEmail: string) => {
    return customAxios.patch(`/api/v1/users/enterprise/member/withdrawal`, {
      hostEmail: hostEmail,
    });
  },
  uploadToS3: ({
    imageUploadUrl,
    image,
  }: {
    imageUploadUrl: string;
    image: File;
  }) => {
    return axios.put(`${imageUploadUrl}`, image, {
      headers: {
        "Content-Type": "image/*",
      },
    });
  },
  disconnectMemeber: (hostEmail: string) => {
    return customAxios.patch(
      `/api/v1/users/enterprise/manager/disconnectMember`,
      hostEmail,
    );
  },
  commentEdit: (data: CommentEditRequest) => {
    return customAxios.put(`/api/v1/questions/components`, data);
  },
  // 리포트 상세페이지 편집
  reportItemsEdit: ({
    hostUuid,
    testUuid,
    testerId,
    events,
    isAroundSettingDisabled,
  }: {
    hostUuid: string;
    testUuid: string;
    testerId: string;
    events: number[];
    isAroundSettingDisabled: boolean;
  }) => {
    return customAxios.patch(
      `/api/v1/admin/hosts/${hostUuid}/tests/${testUuid}/report/${testerId}`,
      { events, isAroundSettingDisabled },
    );
  },
};

export default patchApi;
