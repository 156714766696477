import { Dispatch, SetStateAction } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import OnChangeInput from "../atom/OnchangeInput";
import TextLine from "../atom/TextLine";
import Img from "../atom/Img";

import inputReset from "../../img/inputClean.svg";

interface LoginInputSectionProps {
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  emailErr: string;
  pwErr: string;
  hostEmail: string;
  hostPassword: string;
  emailFocus: boolean;
  pwFocus: boolean;
  setEmailErr: Dispatch<SetStateAction<string>>;
  setPwErr: Dispatch<SetStateAction<string>>;
  setHostEmail: Dispatch<SetStateAction<string>>;
  setHostPassword: Dispatch<SetStateAction<string>>;
  setEmailFocus: Dispatch<SetStateAction<boolean>>;
  setPwFocus: Dispatch<SetStateAction<boolean>>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const LoginInputSection: React.FC<LoginInputSectionProps> = ({
  emailRef,
  passwordRef,
  emailErr,
  pwErr,
  hostEmail,
  hostPassword,
  emailFocus,
  pwFocus,
  setEmailErr,
  setPwErr,
  setHostEmail,
  setHostPassword,
  setEmailFocus,
  setPwFocus,
  handleKeyDown,
}) => {
  return (
    <DivTwoPartImprove
      display="flex"
      flex_direction="column"
      width="349px"
      gap={12}
    >
      <TextLine text={"이메일 주소 (로그인  ID)"} fontFamily="medium" />
      <DivTwoPartImprove display="flex" flex_direction="column" gap={8}>
        <DivTwoPartImprove
          display="flex"
          align_items="center"
          position="relative"
        >
          <OnChangeInput
            inputRef={emailRef}
            type="text"
            width="100%"
            placeholderText="이메일 주소 입력"
            placeholdercolor="#BBB"
            padding="8px 2px"
            border="none"
            value={hostEmail || ""}
            border_bottom={emailErr ? "1px solid #C83B38" : "1px solid #BBB"}
            lineheight={24}
            onchange={(e) => {
              setHostEmail(e.target.value);
              setEmailErr("");
            }}
            onFocus={() => setEmailFocus(true)}
            onKeyDown={handleKeyDown}
          />
          {hostEmail && emailFocus && (
            <Img
              src={inputReset}
              position="absolute"
              right="5px"
              cursor="pointer"
              onclick={() => setHostEmail("")}
            />
          )}
        </DivTwoPartImprove>
        {emailErr && (
          <TextLine
            text={emailErr}
            padding="5px 0"
            fontsize={12}
            lineheight={18}
            fontcolor="#C83B38"
          />
        )}
        <DivTwoPartImprove
          display="flex"
          align_items="center"
          position="relative"
        >
          <OnChangeInput
            inputRef={passwordRef}
            type="password"
            width="100%"
            placeholderText="비밀번호 입력"
            placeholdercolor="#BBB"
            padding="8px 2px"
            border="none"
            value={hostPassword}
            border_bottom={pwErr ? "1px solid #C83B38" : "1px solid #BBB"}
            lineheight={24}
            autoComplete="off"
            onchange={(e) => {
              setHostPassword(e.target.value.replace(/\s| /gi, ""));
              setPwErr("");
            }}
            onFocus={() => setPwFocus(true)}
            onKeyDown={handleKeyDown}
          />
          {hostPassword && pwFocus && (
            <Img
              src={inputReset}
              position="absolute"
              right="5px"
              cursor="pointer"
              onclick={() => setHostPassword("")}
            />
          )}
        </DivTwoPartImprove>
        {pwErr && (
          <TextLine
            text={pwErr}
            padding="5px 0"
            fontsize={12}
            lineheight={18}
            fontcolor="#C83B38"
          />
        )}
      </DivTwoPartImprove>
    </DivTwoPartImprove>
  );
};

export default LoginInputSection;
