import React from "react";
import styled from "styled-components";

interface buttonProps extends buttonStyle {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface buttonStyle {
  width?: string;
  border: boolean;
  margin?: string;
  height?: string;
}

const WeekSmallButton: React.FC<buttonProps> = (props) => {
  return (
    <StButton
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      border={props.border}
      margin={props.margin}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : "37px")};
  font-family: regular;
  font-size: 16px;
  line-height: 21px;
  margin: ${(props) => props.margin};
  background-color: var(--week_fill);
  border: ${(props) => (props.border ? "1px solid var(--border)" : "none")};
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 24px;
  &:hover {
    background-color: var(--week_hover);
  }
  &:active {
    background-color: var(--week_actived);
  }
  &:disabled {
    background-color: var(--primary_disabled);
    border: none;
    color: var(--text_filledBtn);
    cursor: default;
  }
  cursor: pointer;
`;

export default WeekSmallButton;
