import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportSettingPhoto = ({
  testerId,
  testId,
  hostUuid,
  isAdmin,
  isEdit,
}) => {
  const fetcher = async () => {
    // const utc = new Date().getTimezoneOffset();
    const { data } = isAdmin
      ? await apis.getReportSettingPhotoAdmin({
          // utc,
          testId,
          hostUuid,
          testerId,
        })
      : await apis.getReportSettingPhoto({
          // utc,
          testId,
          testerId,
        });
    return data;
  };
  return useQuery(
    ["reportPhoto", testerId, testId, hostUuid, isAdmin, isEdit],
    fetcher,
    {
      onError: (data) => {
        console.log(data);
      },
    },
  );
};

export default useGetReportSettingPhoto;
