import React from 'react';
import styled from 'styled-components';

interface buttonProps extends buttonStyle {
  text: string;
  onClick?: () => void;
}

interface buttonStyle {
  width?: string;
  margin?: string;
}

const WeekButton: React.FC<buttonProps> = (props) => {
  return (
    <StButton width={props.width} margin={props.margin} onClick={props.onClick}>
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonStyle>`
  min-width: 169px;
  width: ${(props) => props.width};
  height: 52px;
  font-family: medium;
  font-size: 17px;
  line-height: 25px;
  background-color: var(--week_fill);
  border: 1px solid var(--text_primary);
  margin: ${(props) => props.margin};
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 43px;
  &:hover {
    background-color: var(--week_hover);
  }
  &:active {
    background-color: var(--week_actived);
  }
  &:disabled {
    background-color: var(--primary_disabled);
    border: none;
    color: var(--text_filledBtn);
    cursor: default;
  }
  cursor: pointer;
`;

export default WeekButton;
