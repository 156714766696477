import { useEffect, useRef, useState } from "react";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import SearchBox from "../atomic/molecule/SearchBox";
import AdminUserListBox from "../atomic/organism/AdminUserListBox";
import useGetHostsByAdmin from "../hooks/api/useGetHostsByAdmin";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCookie } from "../Cookie";
import jwtDecode from "jwt-decode";
import { DecodedAdminToken } from "../interface/authInterface";
import useGetAdminInfo from "../hooks/api/useGetAdminInfo";

interface adminUserListProps {
  adminLogin: boolean;
}

const AdminUserList: React.FC<adminUserListProps> = ({ adminLogin }) => {
  const navigate = useNavigate();
  const token = getCookie("token");
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>("");
  const searchRef = useRef<HTMLInputElement>(null);
  const { data } = useGetHostsByAdmin({
    page: currentPage,
    limit: pageLimit,
    search: searchString,
  });
  const hosts = data?.hosts;
  const pages = data?.meta;

  useEffect(() => {
    if (token && (jwtDecode(token) as DecodedAdminToken).role !== "admin") {
      navigate(`/admin`);
    }
  }, [token]);

  useEffect(() => {
    setSearchString(searchParams.get("search") ?? "");
  }, [location]);

  return (
    <DivTwoPartImprove
      display="flex"
      flex_direction="column"
      gap={40}
      justify="center"
      align_items="center"
      padding="80px 106px"
      background_color="#F8F8F8"
      min_height="calc(100vh - 76px)"
    >
      <SearchBox
        searchRef={searchRef}
        searchParams={searchParams}
        setSearchString={setSearchString}
        setSearchParams={setSearchParams}
      />
      <AdminUserListBox
        listCount={pages?.totalCount ?? 0}
        testType="page"
        pageListCount={pages?.limit ?? 10}
        lastPage={pages?.totalPage ?? 0}
        hosts={hosts}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </DivTwoPartImprove>
  );
};

export default AdminUserList;
