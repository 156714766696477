import React, { useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import useGetReport from "../hooks/useGetReport";
import axios from "axios";
import { baseURL } from "../api/handler";
import Photo from "../modal/ReportSpecificPhoto";
import ModalFormD from "../components/ModalFormD";
import customAxios from "../api/handler";

// img
import arrow from "../img/arrow.svg";
import down from "../img/down.svg";
import normal from "../img/normal.svg";
import suspicious from "../img/suspicious.svg";
import Bad from "../img/Bad.svg";
import checking from "../img/dropdown_check.svg";
import uploadNotCompleted from "../img/uploadNotCompleted.svg";
import incompleteTest from "../img/incompleteTest.svg";
import detectError from "../img/detectError.svg";
import questionMark from "../img/Q.svg";
import sharing from "../img/sharing.svg";
import whiteCheck from "../img/white_ch.svg";
/** 티켓ID 77 관련 추가 */
import maximize from "../img/maximize.svg";

// component
import { Body } from "../img/bodypartTwo";
import { device } from "../hooks/device";
import Action from "./Action";
import { getCookie } from "../Cookie";
import { useEffect } from "react";
import { useState } from "react";
import useGetReportUserList from "../hooks/useGetReportUserList";
import UserList from "./UserList";
import Loading from "./Loading";
import { useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SelectedToolTip, clickInfo, languageIs, userInfo } from "../atoms";
import Graph from "./Graph";
import dayjs from "dayjs";
import EventBar from "./EventBar";
import useGetReportSettingPhoto from "../hooks/useGetReportSettingPhoto";
import EventTable from "./EventTable";
import SendingVideo from "../modal/ReportSpecificSendingVideo";
import RawData from "../modal/RawData";
import SendingConfirm from "../modal/SendingConfirm";
import TextLine from "../atomic/atom/TextLine";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
// 티켓ID 83 관련 추가
import HoverToolTipBadge from "./HoverToolTipBadge";
import SelectedToolTipBadge from "./SelectedToolTipBadge";
/** 티켓ID 77 관련 추가 */
import ActionMaximize from "../modal/ActionMaximize";
import SendingPrivacyCheck from "../modal/SendingPrivacyCheck";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import PrimaryButton from "../atomic/molecule/Button/PrimaryButton";
import WeekButton from "../atomic/molecule/Button/WeekButton";
import jwtDecode from "jwt-decode";
import WeekSmallButton from "../atomic/molecule/Button/WeekSmallButton";
import NegativeButton from "../atomic/molecule/Button/NegativeButton";
import patchApi from "../api/patchApi";

const ReSpUp = ({ setModalOn, reSpLocation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getCookie("token");
  const userData = useRecoilValue(userInfo);
  const uid = userData.hostUuid;
  const testerId = useParams().testerId;
  const testId = useParams().testId;
  const videoId = useParams().videoId;
  const utc = new Date().getTimezoneOffset();
  const myVideo = useRef();
  const country = useRecoilValue(languageIs);
  const [show, setShow] = useState(1);
  const [open, setOpen] = useState(false);
  const [stating, setStating] = useState("#3C9C9C");
  const [testVideo, setTestVideo] = useState("");
  const [testUser, setTestUser] = useState();
  const [width, setWidth] = useState(undefined);
  const [auto, setAuto] = useState(false);
  const clickedFirst = useRef();
  const timeout = useRef(null);
  const scrollTimeout = useRef(null);
  const [test, setTest] = useState();
  const [environmentFilter, setEnvironmentFilter] = useState(1);
  const [cameraSettingFilter, setCameraSettingFilter] = useState(1);
  const [photoModalList, setPhotoModalList] = useState();
  const [videoLink, setVideoLink] = useState();
  const [encodedVideoLink, setEncodedVideoLink] = useState();
  const [startTime, setStartTime] = useState();
  const [duration, setDuration] = useState();
  const [lang, setLang] = useState("kr");
  const [clickedEvent, setClickedEvent] = useState(0);
  const [rawDataState, setRawDataState] = useState(false);
  const [eventListButton, setEventListButton] = useState(false); // 이벤트 더보기 버튼
  const [scrollbarActive, setScrollbarActive] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  //modal
  const [photoModal, setPhotoModal] = useState(false);
  const [videoChanged, setVideoChanged] = useState(false);
  const [sendingVideoModal, setSendingVideoModal] = useState(false);
  const [rawDataModal, setRawDataModal] = useState(false);
  const [sendingConfirmModal, setSendingConfirmModal] = useState(false);
  /** 티켓ID 77 관련 추가 */
  const [maximizeModal, setMaximizeModal] = useState(false);
  const [sendingPrivacyModal, setSendingPrivacyModal] = useState(false);
  /** 편집 기능 추가 */
  const [clickIndex, setClickIndex] = useRecoilState(clickInfo);
  const [detectedList, setDetectedList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState(new Set());
  const [isDelList, setIsDelList] = useState([]);
  const [scanVerified, setScanVerified] = useState(false);
  const [prevScanVerified, setPrevScanVerified] = useState(false);
  const [prevDelList, setPrevDelList] = useState([]);
  const [codeCount, setCodeCount] = useState({});
  const [prevCodeCount, setPrevCodeCount] = useState({});
  const [disabledData, setDisabledData] = useState(false);
  const { hostUuid } = JSON.parse(localStorage.getItem("newTabData")) || {};

  const showToast = useToast();

  const isAdmin = jwtDecode(token).role === "admin";

  const { data: user, isLoading: userLoading } = useGetReport({
    testerId,
    testId,
    rawDataState,
    hostUuid,
    disabledData,
    isAdmin,
    isEdit,
  });
  const { data: units, isLoading: unitLoading } = useGetReportUserList({
    testId,
    hostUuid,
    isAdmin,
    isEdit,
  });
  const { data: settingPhoto } = useGetReportSettingPhoto({
    testId,
    testerId,
    hostUuid,
    isAdmin,
    isEdit,
  });
  const [graphUnit, setGraphUnit] = useState(300);
  const [photoTitle, setPhotoTitle] = useState();
  const [photoSubTitle, setPhotoSubTitle] = useState();
  // console.log(settingPhoto?.aroundSetting?.preTestPhoto);
  useEffect(() => {
    if (user?.testReport?.timeline?.testVideoLength <= 1800) setGraphUnit(300);
    else if (
      user?.testReport?.timeline?.testVideoLength > 1800 ||
      user?.testReport?.timeline?.testVideoLength <= 3600
    )
      setGraphUnit(600);
    else setGraphUnit(1800);
  }, [user]);

  const [columnCount, setColumnCount] = useState();
  const [tableFillCount, setTableFillCount] = useState();

  // 티켓ID 83 관련 추가
  const setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  const scrollContainerRef = useRef(null);

  /** 편집 기능 추가 */
  const addEditID = (ID) => {
    setEditID((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(ID);
      return newSet;
    });
  };

  const removeEditID = (ID) => {
    setEditID((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(ID);
      return newSet;
    });
  };

  dayjs.locale("en", {
    weekdays: ["일", "월", "화", "수", "목", "금", "토"],
  });

  useEffect(() => {
    let temp = [];
    for (
      let i = 0;
      i <
      Math.floor(user?.testReport?.timeline?.testVideoLength / graphUnit) - 1;
      i++
    ) {
      temp[i] = i;
    }
    setColumnCount(temp);
  }, [user, graphUnit]);

  useEffect(() => {
    let temp = [];

    for (let i = 0; i < 4 - user?.testReport?.history?.table?.length; i++) {
      temp[i] = i;
    }
    setTableFillCount(temp);
  }, [user]);

  // delList 편집 기능 추가
  useEffect(() => {
    if (user?.testReport?.timetable) {
      const delList = [];
      user?.testReport?.timetable.forEach((value) => {
        delList.push({
          id: value.id,
          code: value.ruleset,
          isDisable: value.isDisable,
          firstState: value.isDisable,
        });
      });

      const result = delList.reduce((acc, item) => {
        const { code, isDisable } = item;

        if (acc[code]) {
          isDisable && acc[code]++;
        } else {
          acc[code] = isDisable ? 1 : 0;
        }

        return acc;
      }, {});

      setCodeCount(result);
      setIsDelList(delList);
    }
  }, [user, isEdit]);

  const makeColor = (act) => {
    let color;
    if (act === "cheat") {
      color = "#C54E4E";
    } else if (act === "abnormal") {
      color = "#FFD561";
    } else {
      color = "#8CE0AE";
    }
    return color;
  };

  function handleResize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  // window 사이즈를 고려해 그래프 height 조절해주기
  useEffect(() => {
    if (width < 1140) {
      setState("198");
    } else {
      setState("325");
    }
  }, [width]);

  useEffect(() => {
    videoExtract();
  }, [user]);

  const check = user?.testReport?.conclusion?.check;

  // 색 입혀주기
  useEffect(() => {
    if (check) {
      if (check === "cheat") {
        setStating("#C54E4E");
      } else if (check === "abnormal") {
        setStating("#FFD561");
      } else if (check === "normal") {
        setStating("#3C9C9C");
      } else {
        setStating("#BDBDBD");
      }
    }
  }, [check]);

  useEffect(() => {
    getUser();
  }, [uid, videoId]);

  useEffect(() => {
    if (settingPhoto?.aroundSetting) {
      setScanVerified(settingPhoto?.aroundSetting?.isVerifiedEnvironment);
    }
  }, [settingPhoto]);

  useEffect(() => {
    const container = document.getElementById("scrollableContainer");
    if (container) {
      container.addEventListener("scroll", handleScrollbarVisibility);
      return () => {
        container.removeEventListener("scroll", handleScrollbarVisibility);
      };
    }
  }, []);

  const colorOne = makeColor(user?.testReport?.analysis?.[0]?.level); // 사물
  const colorTwo = makeColor(user?.testReport?.analysis?.[1]?.level); // 기타
  const colorThree = makeColor(user?.testReport?.analysis?.[2]?.level); // 얼굴
  const colorFour = makeColor(user?.testReport?.analysis?.[3]?.level); // 몸
  const colorFive = makeColor(user?.testReport?.analysis?.[4]?.level); // 손, 팔

  const getUser = () => {
    const apiUrl = isAdmin
      ? `${baseURL}/api/v1/admin/hosts/${hostUuid}/tests/${testId}/report/uploader-list?offset=${utc}`
      : `${baseURL}/api/v1/tests/${testId}/report/uploader-list?offset=${utc}`;
    axios
      .get(apiUrl, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(() => {
        setTestUser(user);
      })
      .catch(({ data }) => {
        console.log(data);
      });
  };

  const cheating = user?.testReport?.timetable?.filter(
    (user) => user?.level === "cheat",
  );
  const abnormal = user?.testReport?.timetable?.filter(
    (user) => user?.level === "abnormal",
  );

  // eslint-disable-next-line
  const [state, setState] = useState("300");

  const videoExtract = () => {
    const apiUrl = isAdmin
      ? `${baseURL}/api/v1/admin/hosts/${hostUuid}/tests/${testId}/report/${testerId}/s3-files?purpose=video-url`
      : `${baseURL}/api/v1/tests/${testId}/report?testerId=${testerId}&purpose=video-url`;

    customAxios
      .get(apiUrl)
      .then(({ data }) => {
        setTestVideo(data);
        setTest(
          <video
            id="video"
            ref={myVideo}
            playsInline
            preload="auto"
            src={data.videoUrl}
            autoPlay={auto}
            className="userVideo"
            height="298px"
            width="530px"
            controls
          />,
        );
      })
      .catch(() => {
        showToast({ message: t(`errorCode.unknown_server_error`) });
      });
  };

  const videoPlay = () => {
    setAuto(true);
  };

  const closing = () => {
    setOpen(false);
  };

  /** 선택한 영상의 보낼 내용과 제목 */
  let sendingMail =
    `mailto:${user?.testReport?.uploader?.uploaderEmail}?body=%E2%80%BB%E2%80%BB%20%EC%95%84%EB%9E%98%EC%9D%98%20%EC%8B%9C%ED%97%98%20%EC%A0%95%EB%B3%B4%EC%99%80%20%EC%98%81%EC%83%81%20URL%20%EC%A0%95%EB%B3%B4%EB%A5%BC%20%ED%99%95%EC%9D%B8%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94%20%E2%80%BB%E2%80%BB` +
    "%0D%0D" +
    "-------------------------------------------------------------------" +
    "%0D%0D" +
    "-%20%EC%8B%9C%ED%97%98%20%EC%A0%9C%EB%AA%A9%0D%0A" + // 시험 제목
    `: ${user?.test?.testName}` +
    "%0D%0D" +
    "-%20%EC%8B%9C%ED%97%98%20%EB%82%A0%EC%A7%9C%EC%99%80%20%EC%8B%9C%EA%B0%84%0D%0A" + // 시험 날짜와 시간
    `: ${dayjs(user?.test?.testStartTime).format(
      "YYYY-MM-DD(ddd), A HH:mm",
    )} ~ ${dayjs(user?.test?.testEndTime).format("A HH:mm")}` +
    "%0D%0D" +
    "-%20%EC%A3%BC%EC%B5%9C%EC%9E%90%0D%0A" + // 주최자
    `: ${user?.test?.hostName} (${user?.test?.hostEmail})` +
    "%0A%0A" +
    `-%20%ED%99%95%EC%9D%B8%EC%9D%B4%20%ED%95%84%EC%9A%94%ED%95%9C%20%EC%9D%91%EC%8B%9C%20%EC%A4%91%20%EC%9D%B4%EB%B2%A4%ED%8A%B8%20%EC%98%81%EC%83%81%20URL%0D%0A` + // 확인이 필요한 응시 중 이벤트 영상
    `http://proctormatic.com${country}/video?${encodedVideoLink}%0A%0A` +
    "--------------------------------------------------------------------" +
    "%0D%0D";

  let userMail = `mailto:${user?.testReport?.uploader?.uploaderEmail}`;

  const length = units?.uploaderList?.length;

  const setCurTime = (time) => {
    myVideo.current.currentTime = time;
    myVideo.current.play();
  };

  /** 선택한 영상의 링크 가져오기 */
  const selectedVideoSend = () => {
    customAxios
      .post(
        `${process.env.REACT_APP_MOBILE_ADDRESS}/api/v1/clip_video`,
        {
          owner_uid: uid,
          test_tid: testId,
          tester_id: testerId,
          start_time: startTime,
          duration: duration,
        },
        {
          timeout: 600000,
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(({ data }) => {
        setVideoLink(`http://proctormatic.com/video?` + data?.result);
        setEncodedVideoLink(encodeURIComponent(data?.result));
        setVideoReady(false);
        setSendingConfirmModal(false);
        setSendingVideoModal(true);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  const setPauTime = (endTime) => {
    timeout.current = setTimeout(() => {
      myVideo?.current?.pause();
      // console.log('멈춤!')
    }, endTime);
  };

  const clearTimeOutForVideo = () => {
    clearTimeout(timeout.current);
  };

  /** 1초가 지난 뒤에 스크롤 바가 안보이게 하기 */
  const handleScrollbarVisibility = () => {
    if (!scrollbarActive) {
      setScrollbarActive(true);
    }
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      setScrollbarActive(false);
    }, 1000);
  };

  /** 편집 기능 관련 추가 */
  const setItemClicked = (index) => {
    setClickIndex(index);
    setClickedEvent(index + 1);
    setCurTime(detectedList[index]?.startTime);
    setStartTime(detectedList[index]?.startTime);
    setDuration(detectedList[index]?.endTime - detectedList[index]?.startTime);
    clearTimeOutForVideo();
    setPauTime(
      (detectedList[index]?.endTime - detectedList[index]?.startTime) * 1000,
    );
    // resumeTime(startTime,(endTime-startTime)*1000)
    // 티켓ID 83 관련 추가
    setSelectedToolTip({
      isSelected: true,
      start: detectedList[index]?.startTime,
      end: detectedList[index]?.endTime,
      level: detectedList[index]?.level,
      index: index,
    });
  };

  /** 아이템 리스트에서 키보드 처리 */
  const onKeyDownOnItemList = (event) => {
    let cindex;
    switch (event.key) {
      case "ArrowUp":
        cindex = Math.max(clickIndex - 1, 0);
        setItemClicked(cindex);
        break;
      case "ArrowDown":
        cindex = Math.min(clickIndex + 1, detectedList.length - 1);
        setItemClicked(cindex);
        break;
      case "e":
      case "E":
        if (isEdit) {
          setIsDelList((prev) => {
            const updateList = prev.map((value, i) => {
              if (i === clickIndex) {
                removeEditID(value.id);
                !value.firstState && addEditID(value.id);
                return { ...value, isDisable: true };
              }
              return value;
            });

            return updateList;
          });

          if (!isDelList[clickIndex].isDisable) {
            setCodeCount((prev) => ({
              ...prev,
              [isDelList[clickIndex].code]:
                (prev[isDelList[clickIndex].code] || 0) + 1,
            }));
          }
        }
        break;
      case "r":
      case "R":
        if (isEdit) {
          setIsDelList((prev) => {
            const updateList = prev.map((value, i) => {
              if (i === clickIndex) {
                removeEditID(value.id);
                value.firstState && addEditID(value.id);
                return { ...value, isDisable: false };
              }
              return value;
            });

            return updateList;
          });

          if (isDelList[clickIndex].isDisable) {
            setCodeCount((prev) => ({
              ...prev,
              [isDelList[clickIndex].code]:
                (prev[isDelList[clickIndex].code] || 0) - 1,
            }));
          }
        }
        break;
      default:
        console.log("ETC!");
        break;
    }

    event.preventDefault();
  };

  const reportItemsUpdate = () => {
    patchApi
      .reportItemsEdit({
        hostUuid: hostUuid,
        testUuid: testId,
        testerId: testerId,
        events: Array.from(editID),
        isAroundSettingDisabled: !scanVerified,
        testerId: testerId,
      })
      .then(() => {
        showToast({ message: "저장이 완료 되었습니다." });
        setIsEdit(false);
        setDisabledData(false);
      })
      .catch(({ response }) => {
        console.log(response);
        showToast({ message: "알 수 없는 에러가 발생했습니다." });
      });
  };

  useEffect(() => {
    if (show === 1) {
      setDetectedList(user?.testReport?.timeline?.timelineTable);
    } else if (show === 2) {
      setDetectedList(cheating);
    } else {
      setDetectedList(abnormal);
    }
  }, [show, user, cheating, abnormal]);

  useEffect(() => {
    if (isEdit) setDisabledData(true);
    else setDisabledData(false);
    setPrevScanVerified(scanVerified);
    setPrevDelList(isDelList);
    setPrevCodeCount(codeCount);
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [rawDataState]);

  useEffect(() => {
    setRawDataState(false);
    setIsEdit(false);
  }, [testerId]);

  return (
    <>
      {videoChanged && (
        <ModalFormD
          close={setVideoChanged}
          title="영상 변조 여부 정보"
          textOne="프록토매틱 앱(응시자용)을 통해 촬영 및 녹화되었는지 여부와 해당 영상이 시험 종료 이후 별도의 편집이 이뤄졌는지에 대한 유효성 검증을 진행한 결과에요. "
        />
      )}
      {rawDataModal && (
        <RawData
          setRawDataModal={setRawDataModal}
          setRawDataState={setRawDataState}
          rawDataState={rawDataState}
        />
      )}
      <StBack
        $clickedEvent={clickedEvent}
        graphUnit={graphUnit}
        videoLength={user?.testReport?.timeline?.testVideoLength}
        length={length}
        rawData={rawDataState}
        show={show}
        open={open}
        stating={stating}
        state1={colorOne}
        state2={colorTwo}
        state3={colorThree}
        state4={colorFour}
        state5={colorFive}
        environmentFilter={environmentFilter}
        cameraSettingFilter={cameraSettingFilter}
        tempered={scanVerified}
        showScrollbar={scrollbarActive}
        studentCount={units?.uploaderList?.length}
      >
        {photoModal && (
          <Photo
            photoModalList={photoModalList}
            setPhotoModal={setPhotoModal}
            title={photoTitle}
            subTitle={photoSubTitle}
          />
        )}
        {sendingVideoModal && (
          <SendingVideo
            videoLink={videoLink}
            setSendingVideoModal={setSendingVideoModal}
            sendingMail={sendingMail}
            setVideoReady={setVideoReady}
            videoReady={videoReady}
            testerEmail={user?.testReport?.uploader?.uploaderEmail}
          />
        )}
        {sendingConfirmModal && (
          <SendingConfirm
            setSendingConfirmModal={setSendingConfirmModal}
            selectedVideoSend={selectedVideoSend}
            videoReady={videoReady}
            setVideoReady={setVideoReady}
          />
        )}
        {/** 티켓ID 77 관련 추가 */}
        {maximizeModal && (
          <ActionMaximize
            user={user}
            graphUnit={graphUnit}
            columnCount={columnCount}
            setMaximizeModal={setMaximizeModal}
          />
        )}
        {sendingPrivacyModal && (
          <SendingPrivacyCheck
            setSendingPrivacyModal={setSendingPrivacyModal}
            setSendingConfirmModal={setSendingConfirmModal}
            setModalOn={setModalOn}
          />
        )}
        <div className="body">
          <div className="upTitle">
            <div className="flexing">
              <img
                className="arrow"
                alt=""
                onClick={() => {
                  // navigate(`/report/${testId}`);
                  // 쿼리스트링 추가해서 뒤로가기 기능을 사용해야 함
                  // navigate(-1);
                  // 상세페이지에서 보고서를 변경하는 드롭다운 메뉴가 있어서 변경
                  reSpLocation
                    ? navigate(reSpLocation)
                    : navigate(`/report/${testId}`);
                }}
                src={arrow}
              />
              <span>검증 결과보고서</span>
            </div>
            <div className="users">
              {!(unitLoading || userLoading) && (
                <div
                  className="studentShowingOfThisPage"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <div className="state" />
                  <span className="naming">
                    {user?.testReport?.uploader?.uploaderName}
                  </span>
                  <span className="counting">
                    {check === "incompleteTest" ||
                    check === "errorCase" ||
                    check === "uploadNotCompleted"
                      ? "-"
                      : user?.testReport?.conclusion?.cheatCount +
                        user?.testReport?.conclusion?.abnormalCount}
                  </span>
                  <img src={down} alt="" />
                </div>
              )}
              {open && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      zIndex: "2",
                      width: "100%",
                      height: "100%",
                      left: "0",
                      top: "0",
                      cursor: "default",
                    }}
                    onClick={closing}
                  />
                  <div className="studentListBox">
                    <div
                      ref={clickedFirst}
                      className="studentOfThisPage"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <div className="state" />
                      <span className="naming">
                        {user?.testReport?.uploader?.uploaderName}
                      </span>
                      <span className="counting">
                        {check === "incompleteTest" ||
                        check === "errorCase" ||
                        check === "uploadNotCompleted"
                          ? "-"
                          : user?.testReport?.conclusion?.cheatCount +
                            user?.testReport?.conclusion?.abnormalCount}
                      </span>
                      <img src={checking} alt="" />
                    </div>
                    <div className="listing">
                      {units?.uploaderList?.map((value, index) => (
                        <UserList
                          key={index}
                          nowId={testerId}
                          testId={testId}
                          testerId={value?.testerId}
                          uploaderName={value?.uploaderName}
                          totalEventCount={value?.totalEventCount}
                          check={value?.check}
                          close={closing}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="reportContent">
            {unitLoading || userLoading ? (
              <Loading />
            ) : (
              <>
                <img
                  className="userPhoto"
                  src={user?.testReport?.uploader?.uploaderPhoto}
                  alt=""
                />
                <div className="testerInformationBox">
                  <div className="eachItemBox">
                    <div className="titlePart">응시자 이름</div>
                    <div className="namePart">
                      {user?.testReport?.uploader?.uploaderName}
                    </div>
                  </div>
                  <div className="eachItemBox">
                    <div className="titlePart">촬영 시작시간</div>
                    <div className="bodyPart">
                      {dayjs(
                        user?.testReport?.uploader?.uploaderStartTime,
                      ).format("YYYY-MM-DD(ddd), A hh:mm")}{" "}
                      (UTC+{-utc / 60})
                    </div>
                  </div>
                  <div className="eachItemBox">
                    <div className="titlePart">응시자 이메일</div>
                    <a className="emailPart" href={userMail}>
                      {user?.testReport?.uploader?.uploaderEmail}
                    </a>
                  </div>
                  {user?.testReport?.isTesterSelfExit ? (
                    <div className="eachItemBox">
                      <div className="titlePart">촬영 종료시간</div>
                      <div className="bodyPart">
                        {dayjs(
                          user?.testReport?.uploader?.finishTestTime,
                        ).format("YYYY-MM-DD(ddd), A hh:mm")}{" "}
                        (UTC+{-utc / 60})
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="eachItemBox">
                    <div className="titlePart">응시자 접속 지역</div>
                    <div className="bodyPart">
                      {user?.testReport?.uploader?.uploaderLocation}
                    </div>
                  </div>
                  <div className="eachItemBox">
                    <div className="titlePart">영상 업로드 시간</div>
                    <div className="bodyPart">
                      {dayjs(
                        user?.testReport?.uploader?.uploadVideoTime,
                      ).format("YYYY-MM-DD(ddd), A hh:mm")}{" "}
                      (UTC+{-utc / 60})
                    </div>
                  </div>
                  <div className="eachItemBox" />
                  <div className="eachItemBox">
                    <div className="titlePart">
                      영상 변조 여부{" "}
                      <img
                        src={questionMark}
                        onClick={() => setVideoChanged(true)}
                        alt=""
                      />
                    </div>
                    {user?.testReport?.uploader?.isTamperedVideo === "-" ? (
                      <div className="bodyPart">-</div>
                    ) : (
                      <div
                        className="bodyPart"
                        style={
                          user?.testReport?.uploader?.isTamperedVideo === true
                            ? { color: "#C83B38" }
                            : {}
                        }
                      >
                        {user?.testReport?.uploader?.isTamperedVideo === true
                          ? "원본이 아니거나 편집 영상으로 의심됨"
                          : "정상"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="synthesizeOpinionBox">
                  <div className="title">프록토매틱 종합 소견</div>
                  <div className="bodyPart">
                    {user?.testReport?.conclusion?.check === "cheat" && (
                      <img className="image" alt="bad" src={Bad} />
                    )}
                    {user?.testReport?.conclusion?.check === "abnormal" && (
                      <img className="image" alt="middle" src={suspicious} />
                    )}
                    {user?.testReport?.conclusion?.check === "normal" && (
                      <img className="image" alt="good" src={normal} />
                    )}
                    {user?.testReport?.conclusion?.check ===
                      "incompleteTest" && (
                      <img className="image" alt="good" src={incompleteTest} />
                    )}
                    {user?.testReport?.conclusion?.check ===
                      "uploadNotCompleted" && (
                      <img
                        className="image"
                        alt="good"
                        src={uploadNotCompleted}
                      />
                    )}
                    {user?.testReport?.conclusion?.check === "errorCase" && (
                      <img className="image" alt="error" src={detectError} />
                    )}
                    <div className="talk">
                      <div className="triangle" />
                      <div className="messageBalloon">
                        {user?.testReport?.status === "tamperedVideo" && (
                          <>
                            <div className="mainMessage">
                              응시자가 업로드한 영상의 변조가 확인되었어요!
                            </div>
                            <div className="subMessage">
                              실제 촬영 및 녹화된 원본이 아니거나 편집된
                              영상으로 확인됨. 응시자에게 직접 연락하여 확인을
                              요망함.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status ===
                          "cheatCameraOffUnverifiedEnv" && (
                          <>
                            <div className="mainMessage">
                              부정행위로 의심되는 이벤트(
                              {user?.testReport?.conclusion?.cheatCount}건),
                              응시 중 카메라 꺼짐 (
                              {user?.testReport?.conclusion?.cameraOffCount}
                              건)과 주변환경 스캔 내역 중 위반행위가
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "cheatUnverifiedEnv" && (
                          <>
                            <div className="mainMessage">
                              부정행위로 의심되는 이벤트(
                              {user?.testReport?.conclusion?.cheatCount}건)과
                              주변환경 스캔 내역 중 위반행위가 감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "cheatCameraOff" && (
                          <>
                            <div className="mainMessage">
                              부정행위로 의심되는 이벤트(
                              {user?.testReport?.conclusion?.cheatCount}건)과
                              응시 중 카메라 꺼짐 (
                              {user?.testReport?.conclusion?.cameraOffCount}
                              건)이 감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status ===
                          "unverifiedEnvCameraOff" && (
                          <>
                            <div className="mainMessage">
                              주변환경 스캔 내역 중 위반행위와 응시 중 카메라
                              꺼짐 (
                              {user?.testReport?.conclusion?.cameraOffCount}
                              건)이 감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status ===
                          "unverifiedEnvAbnormal" && (
                          <>
                            <div className="mainMessage">
                              주변환경 스캔 내역 중 위반행위와 이상행동으로
                              감지된 이벤트(
                              {user?.testReport?.conclusion?.abnormalCount}건)이
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "unverifiedEnv" && (
                          <>
                            <div className="mainMessage">
                              주변환경 스캔 내역 중 금지물건이 감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 내역에 대한 확인 및 검토가 반드시 필요하며,
                              그 외 감지 항목들에 대해서도 재검토 요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "cameraOffAbnormal" && ( // 8순위
                          <>
                            <div className="mainMessage">
                              응시 중 카메라 꺼짐 (
                              {user?.testReport?.conclusion?.cameraOffCount}
                              건)과 이상행동으로 감지된 이벤트(
                              {user?.testReport?.conclusion?.abnormalCount}건)이
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              소수의 이상행동만 감지됨에 따라 정상 응시로
                              판단되나, 카메라 꺼짐에 대한 확인 및 검토는
                              필요함.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "cameraOff" && (
                          <>
                            <div className="mainMessage">
                              응시 중 카메라 꺼짐 (
                              {user?.testReport?.conclusion?.cameraOffCount}
                              건)이 감지되었어요!
                            </div>
                            <div className="subMessage">
                              별도의 이벤트는 감지되지 않았으나, 카메라 꺼짐에
                              대한 확인 및 검토는 필요함.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "cheatUpload" && ( // MD.3
                          <>
                            <div className="mainMessage">
                              부정행위로 의심되는 이벤트(
                              {user?.testReport?.conclusion?.cheatCount}건)이
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              해당 이벤트 영상에 대한 확인 및 검토가 반드시
                              필요하며, 그 외 감지 항목들에 대해서도 재검토
                              요망.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "abnormalUpload" && ( // MD.3.1
                          <>
                            <div className="mainMessage">
                              이상행동으로 감지된 이벤트(
                              {user?.testReport?.conclusion?.abnormalCount}건)이
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              다수의 이상행동들이 감지됨에 따라 해당 이벤트
                              영상에 대한 확인 및 검토를 권장함.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "normalUpload" && ( // MD.3.2
                          <>
                            <div className="mainMessage">
                              부정행위 또는 이상행동으로 감지된 이벤트가 없어요!
                            </div>
                            <div className="subMessage">
                              별도의 확인 및 검토가 필요없음.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status ===
                          "normalUploadIncludesAbnormal" && ( // MD.3.2.1
                          <>
                            <div className="mainMessage">
                              이상행동으로 감지된 이벤트(
                              {user?.testReport?.conclusion?.abnormalCount}건)이
                              감지되었어요!
                            </div>
                            <div className="subMessage">
                              소수의 이상행동만 감지됨에 따라 정상 응시로
                              판단됨. 필요 시 해당 이벤트 영상에 대한 확인 및
                              검토를 권장함.
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "incompleteTest" && (
                          <>
                            <div className="mainMessage">
                              응시를 정상적으로 끝마치지 못했어요!
                            </div>
                            <div className="subMessage">
                              응시는 시작했으나, 끝까지 완료하지 못한 것으로
                              확인됨.
                            </div>
                            <div className="timeMessage">
                              <div>응시 시작</div>
                              <span>
                                {dayjs(
                                  user?.testReport?.conclusion?.startTestTime,
                                ).format("A hh:mm:ss")}{" "}
                                (UTC+{-utc / 60})
                              </span>
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "uploadNotCompleted" && (
                          <>
                            <div className="mainMessage">
                              응시 영상 업로드가 정상적으로 진행되지 않았어요!
                            </div>
                            <div className="subMessage">
                              응시와 녹화는 정상적으로 완료된 것으로 확인되나,
                              해당 영상의 업로드는 정상적으로 진행되지 않음.
                            </div>
                            <div className="timeMessage">
                              <div>응시 시작</div>
                              <span>
                                {dayjs(
                                  user?.testReport?.conclusion?.startTestTime,
                                ).format("A hh:mm:ss")}{" "}
                                (UTC+{-utc / 60})
                              </span>
                            </div>
                            <div className="timeMessage">
                              <div>응시 종료</div>
                              <span>
                                {user?.testReport?.conclusion
                                  ?.finishTestTime === "-"
                                  ? "-"
                                  : dayjs(
                                      user?.testReport?.conclusion
                                        ?.finishTestTime,
                                    ).format("A hh:mm:ss")}{" "}
                                (UTC+{-utc / 60})
                              </span>
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                        {user?.testReport?.status === "errorCase" && (
                          <>
                            <div className="mainMessage">
                              응시 영상을 분석 중에 알 수 없는 오류가
                              발생하였어요!
                            </div>
                            <div className="subMessage">
                              응시와 녹화, 영상 업로드는 정상적으로 완료된
                              것으로 확인되나, 알 수 없는 오류가 발생하여 영상
                              분석 진행이 정상적으로 처리되지 않았어요.
                              고객센터를 통해 연락주시면 신속하게 도와드릴게요.
                            </div>
                            <div className="timeMessage">
                              <div>응시 시작</div>
                              <span>
                                {dayjs(
                                  user?.testReport?.conclusion?.startTestTime,
                                ).format("A hh:mm:ss")}{" "}
                                (UTC+{-utc / 60})
                              </span>
                            </div>
                            <div className="timeMessage">
                              <div>응시 종료</div>
                              <span>
                                {user?.testReport?.conclusion
                                  ?.finishTestTime === "-"
                                  ? "-"
                                  : dayjs(
                                      user?.testReport?.conclusion
                                        ?.finishTestTime,
                                    ).format("A hh:mm:ss")}
                              </span>
                            </div>
                            <div className="notificationMessage">
                              *아래 규칙 위반 내역 및 감지된 영상 참조
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {(user?.testReport?.status !== "incompleteTest") &
                (user?.testReport?.status !== "uploadNotCompleted") &
                (user?.testReport?.status !== "errorCase") ? (
                  <div className="detectedEventBox">
                    <div className="title">감지된 이벤트 내역</div>
                    <div className="bodyPart">
                      <div className="total">
                        <div className="inside">
                          <div className="what">
                            <div> 총 이벤트 </div>
                          </div>
                          <div className="down">
                            {" "}
                            <span className="num">
                              {
                                user?.testReport?.history?.summary
                                  ?.totalEventCount
                              }
                            </span>
                            건{" "}
                          </div>
                        </div>
                        <div className="inside">
                          <div className="what">
                            <div className="circleRed" />
                            <div> 부정행위 </div>
                          </div>
                          <div className="down">
                            {" "}
                            <span className="num">
                              {
                                user?.testReport?.history?.summary
                                  ?.cheatEventCount
                              }
                            </span>
                            건{" "}
                          </div>
                        </div>
                        <div className="inside">
                          <div className="what">
                            <div className="circleYellow" />
                            <div> 이상행동 </div>
                          </div>
                          <div className="down">
                            {" "}
                            <span className="num">
                              {
                                user?.testReport?.history?.summary
                                  ?.abnormalEventCount
                              }
                            </span>
                            건{" "}
                          </div>
                        </div>
                      </div>
                      <div className="bodyRight">
                        <div
                          className="rawDataLine"
                          onClick={() => {
                            if (rawDataState === true) {
                              setRawDataState(false);
                            } else {
                              setRawDataModal(true);
                            }
                          }}
                        >
                          <div className="rawDataCheckBox">
                            {rawDataState && <img src={whiteCheck} alt="" />}
                          </div>
                          원본 내역 포함
                        </div>
                        <div className="eventTable">
                          <div className="tableHeader">
                            <div>부정행위 / 이상행동 이벤트</div>
                            <div className="accCount">건수</div>
                            <div>시간</div>
                          </div>
                          <div className="tableMargin">
                            <div> </div>
                            <div className="accCount"> </div>
                            <div> </div>
                          </div>
                          {user?.testReport?.history?.table?.length > 0 ? (
                            <>
                              {user?.testReport?.history?.table?.map(
                                (value, index) => (
                                  <EventTable
                                    code={value?.ruleset}
                                    key={index}
                                    eventListButton={eventListButton}
                                    index={index}
                                    sort={value?.sort}
                                    count={value?.accCount}
                                    level={value?.level}
                                    duration={value?.accDuration}
                                    isEdit={isEdit}
                                    setIsDelList={setIsDelList}
                                    codeCount={codeCount}
                                    setCodeCount={setCodeCount}
                                    addEditID={addEditID}
                                    removeEditID={removeEditID}
                                  />
                                ),
                              )}
                            </>
                          ) : (
                            <div className="noEvent">
                              <div
                                style={{ fontSize: "13px", color: "#BDBDBD" }}
                              >
                                이벤트 없음
                              </div>
                              <div className="middleCount">-</div>
                              <div>-</div>
                            </div>
                          )}
                          <div className="tableMargin">
                            <div> </div>
                            <div className="accCount"> </div>
                            <div> </div>
                          </div>
                          {(user?.testReport?.history?.table?.length < 4) &
                          (user?.testReport?.history?.table?.length > 0) ? (
                            <div>
                              {tableFillCount?.map((value, index) => (
                                <div className="fillEvent" key={index}>
                                  <div></div>
                                  <div className="middleCount"></div>
                                  <div></div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                          {!eventListButton &
                          (user?.testReport?.history?.table?.length > 3) ? (
                            <div className="tableShowMore">
                              <div
                                className="eventMore"
                                onClick={() => setEventListButton(true)}
                              >
                                이벤트 더보기
                              </div>
                              <div className="accCount"></div>
                              <div></div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* 편집 기능 추가 */}
                {isAdmin && (
                  <DivTwoPartImprove
                    display="flex"
                    align_items="center"
                    justify="center"
                    pc_margin="60px 0 0 0"
                    gap={24}
                  >
                    {isEdit ? (
                      <>
                        <WeekButton
                          text="취소"
                          width="169px"
                          onClick={() => {
                            setIsEdit(false);
                            setScanVerified(prevScanVerified);
                            setIsDelList(prevDelList);
                            setCodeCount(prevCodeCount);
                            setDisabledData(false);
                          }}
                        />
                        <PrimaryButton
                          text="저장"
                          size="big"
                          width="168px"
                          onClick={reportItemsUpdate}
                        />
                      </>
                    ) : (
                      <PrimaryButton
                        text="편집"
                        size="big"
                        width="168px"
                        onClick={() => {
                          setIsEdit(true);
                          setDisabledData(true);
                          setEditID(new Set());
                        }}
                      />
                    )}
                  </DivTwoPartImprove>
                )}
                {(user?.testReport?.status !== "incompleteTest") &
                (user?.testReport?.status !== "uploadNotCompleted") &
                (user?.testReport?.status !== "errorCase") ? (
                  <div className="detectedVideoBox">
                    <div className="title">
                      <div>감지된 이벤트 영상</div>
                    </div>
                    <div className="timeLinePart">
                      {/**
                       * 티켓링크 : https://www.notion.so/edint/WEB-7125cd39aa0543f590e319c25d70e3ae?pvs=4
                       * 티켓ID : 83
                       * 주석작성자 : Noah
                       * 주석작성일자 : 2024.05.24
                       * 티켓내용 : 결과 리포트 → 감지된 이벤트 영상 관련
                       * 주석설명 : 타임라인 또는 액션바 호버 및 클릭 시 툴팁 표시
                       */}
                      <HoverToolTipBadge
                        videoLength={
                          user?.testReport?.timeline?.testVideoLength
                        }
                      />
                      <SelectedToolTipBadge
                        videoLength={
                          user?.testReport?.timeline?.testVideoLength
                        }
                      />
                      <div className="timeLineInfo">
                        <div>타임라인</div>
                        <div className="rightInfo">
                          <div className="infoItemForm">
                            <div
                              className="circle"
                              style={{ background: "#C83B38" }}
                            />
                            <div>부정행위</div>
                          </div>
                          <div className="infoItemForm">
                            <div
                              className="circle"
                              style={{ background: "#FFBC00" }}
                            />
                            <div>이상행동</div>
                          </div>
                        </div>
                      </div>
                      <div className="timeLineGraphInfo">
                        <div className="fromStartToEnd">
                          <div>
                            {dayjs(
                              // user?.testReport?.timeline?.testStartTime
                              user?.testReport?.uploader?.uploaderStartTime,
                            ).format("A hh:mm")}
                          </div>
                          <div>
                            {dayjs(
                              user?.testReport?.timeline?.testEndTime,
                            ).format("A hh:mm")}
                          </div>
                        </div>
                        <div className="barGraph">
                          <div className="unitBarPart">
                            {columnCount?.map((value, index) => (
                              <div className="unitBar" key={index}>
                                <div>{(graphUnit / 60) * (index + 1)}</div>
                                <div className="columnStick" key={index} />
                              </div>
                            ))}
                          </div>
                          <div className="eventBarPart">
                            {/** 티켓ID 83 관련 추가 */}
                            {show === 1 && (
                              <>
                                {user?.testReport?.timeline?.timelineTable?.map(
                                  (value, index) => (
                                    <EventBar
                                      key={index}
                                      start={value?.startTime}
                                      end={value?.endTime}
                                      level={value?.level}
                                      videoLength={
                                        user?.testReport?.timeline
                                          ?.testVideoLength
                                      }
                                      // 티켓ID 83 관련 추가
                                      eventIndex={index}
                                      clearTimeOut={clearTimeOutForVideo}
                                      setClickedEvent={setClickedEvent}
                                      setPauTime={setPauTime}
                                      setCurTime={setCurTime}
                                      setStartTime={setStartTime}
                                      setDuration={setDuration}
                                    />
                                  ),
                                )}
                              </>
                            )}
                            {show === 2 && (
                              <>
                                {cheating?.map((value, index) => (
                                  <EventBar
                                    key={index}
                                    start={value?.startTime}
                                    end={value?.endTime}
                                    level={value?.level}
                                    videoLength={
                                      user?.testReport?.timeline
                                        ?.testVideoLength
                                    }
                                    // 티켓ID 83 관련 추가
                                    eventIndex={index}
                                    clearTimeOut={clearTimeOutForVideo}
                                    setClickedEvent={setClickedEvent}
                                    setPauTime={setPauTime}
                                    setCurTime={setCurTime}
                                    setStartTime={setStartTime}
                                    setDuration={setDuration}
                                  />
                                ))}
                              </>
                            )}
                            {show === 3 && (
                              <>
                                {abnormal?.map((value, index) => (
                                  <EventBar
                                    key={index}
                                    start={value?.startTime}
                                    end={value?.endTime}
                                    level={value?.level}
                                    videoLength={
                                      user?.testReport?.timeline
                                        ?.testVideoLength
                                    }
                                    // 티켓ID 83 관련 추가
                                    eventIndex={index}
                                    clearTimeOut={clearTimeOutForVideo}
                                    setClickedEvent={setClickedEvent}
                                    setPauTime={setPauTime}
                                    setCurTime={setCurTime}
                                    setStartTime={setStartTime}
                                    setDuration={setDuration}
                                  />
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rightShow">
                      <div>보기기준 :&nbsp;&nbsp;</div>
                      <div
                        className="all"
                        onClick={() => {
                          setShow(1);
                          // 티켓ID 83 관련 추가
                          setSelectedToolTip({ isSelected: false });
                          setClickedEvent(0);
                        }}
                      >
                        전체
                      </div>
                      <div className="greyColumn" />
                      <div
                        className="cheating"
                        onClick={() => {
                          setShow(2);
                          // 티켓ID 83 관련 추가
                          setSelectedToolTip({ isSelected: false });
                          setClickedEvent(0);
                        }}
                      >
                        부정행위
                      </div>
                      <div className="greyColumn" />
                      <div
                        className="abnormal"
                        onClick={() => {
                          setShow(3);
                          // 티켓ID 83 관련 추가
                          setSelectedToolTip({ isSelected: false });
                          setClickedEvent(0);
                        }}
                      >
                        이상행동
                      </div>
                    </div>
                    <div className="videoInfo">
                      {testVideo && test}
                      <div className="cheatingList">
                        {show === 1 && (
                          <div>
                            {user?.testReport?.timetable?.length !== 0 ? (
                              <div
                                ref={scrollContainerRef}
                                id="scrollableContainer"
                                className="videoLeftIn"
                              >
                                {user?.testReport?.timetable?.map(
                                  (value, index) => (
                                    <Action
                                      setClickedEvent={setClickedEvent}
                                      clickedEvent={clickedEvent}
                                      clearTimeOut={clearTimeOutForVideo}
                                      setPauTime={setPauTime}
                                      setCurTime={setCurTime}
                                      videoUrl={testVideo?.videoUrl}
                                      videoPlay={videoPlay}
                                      key={index}
                                      startTime={value.startTime}
                                      endTime={value.endTime}
                                      index={index}
                                      text={value.sort}
                                      timestamp={value.timestamp}
                                      level={value.level}
                                      setStartTime={setStartTime}
                                      setDuration={setDuration}
                                      detectedItem={value.detectedItem}
                                      // 티켓ID 83 관련 추가
                                      scrollContainerRef={scrollContainerRef}
                                      // 편집 기능 추가
                                      isEdit={isEdit}
                                      isDeleted={isDelList[index]?.isDisable}
                                      isDelList={isDelList}
                                      setIsDeleted={setIsDelList}
                                      setCodeCount={setCodeCount}
                                      addEditID={addEditID}
                                      removeEditID={removeEditID}
                                      setItemClicked={setItemClicked}
                                      onKeyDownOnItemList={onKeyDownOnItemList}
                                    />
                                  ),
                                )}
                              </div>
                            ) : (
                              <div className="videoLeftInTwo">이벤트 없음</div>
                            )}
                          </div>
                        )}
                        {show === 2 && (
                          <div>
                            {cheating?.length !== 0 ? (
                              <div
                                ref={scrollContainerRef}
                                id="scrollableContainer"
                                className="videoLeftIn"
                              >
                                {cheating?.map((value, index) => (
                                  <Action
                                    setClickedEvent={setClickedEvent}
                                    clickedEvent={clickedEvent}
                                    clearTimeOut={clearTimeOutForVideo}
                                    setPauTime={setPauTime}
                                    setCurTime={setCurTime}
                                    videoUrl={testVideo?.videoUrl}
                                    videoPlay={videoPlay}
                                    key={index}
                                    startTime={value.startTime}
                                    endTime={value.endTime}
                                    index={index}
                                    text={value.sort}
                                    timestamp={value.timestamp}
                                    level={value.level}
                                    setStartTime={setStartTime}
                                    setDuration={setDuration}
                                    detectedItem={value.detectedItem}
                                    // 티켓ID 83 관련 추가
                                    scrollContainerRef={scrollContainerRef}
                                    // 편집 기능 추가
                                    isEdit={isEdit}
                                    isDeleted={isDelList[index]?.isDisable}
                                    isDelList={isDelList}
                                    setIsDeleted={setIsDelList}
                                    setCodeCount={setCodeCount}
                                    addEditID={addEditID}
                                    removeEditID={removeEditID}
                                    setItemClicked={setItemClicked}
                                    onKeyDownOnItemList={onKeyDownOnItemList}
                                  />
                                ))}
                              </div>
                            ) : (
                              <div className="videoLeftInTwo">이벤트 없음</div>
                            )}
                          </div>
                        )}
                        {show === 3 && (
                          <div>
                            {abnormal?.length !== 0 ? (
                              <div
                                ref={scrollContainerRef}
                                id="scrollableContainer"
                                className="videoLeftIn"
                              >
                                {abnormal?.map((value, index) => (
                                  <Action
                                    setClickedEvent={setClickedEvent}
                                    clickedEvent={clickedEvent}
                                    clearTimeOut={clearTimeOutForVideo}
                                    setPauTime={setPauTime}
                                    setCurTime={setCurTime}
                                    videoUrl={testVideo?.videoUrl}
                                    videoPlay={videoPlay}
                                    key={index}
                                    startTime={value.startTime}
                                    endTime={value.endTime}
                                    index={index}
                                    text={value.sort}
                                    timestamp={value.timestamp}
                                    level={value.level}
                                    setStartTime={setStartTime}
                                    setDuration={setDuration}
                                    detectedItem={value.detectedItem}
                                    // 티켓ID 83 관련 추가
                                    scrollContainerRef={scrollContainerRef}
                                    // 편집 기능 추가
                                    isEdit={isEdit}
                                    isDeleted={isDelList[index]?.isDisable}
                                    isDelList={isDelList}
                                    setIsDeleted={setIsDelList}
                                    setCodeCount={setCodeCount}
                                    addEditID={addEditID}
                                    removeEditID={removeEditID}
                                    setItemClicked={setItemClicked}
                                    onKeyDownOnItemList={onKeyDownOnItemList}
                                  />
                                ))}
                              </div>
                            ) : (
                              <div className="videoLeftInTwo">이벤트 없음</div>
                            )}
                          </div>
                        )}
                        {/* 티켓ID 77 관련 추가 */}
                        <div className="videoButtonBox">
                          <div className="videoSharingButton">
                            <img src={sharing} />
                            <div
                              onClick={() => {
                                if (clickedEvent !== 0) {
                                  // selectedVideoSend();
                                  // setSendingConfirmModal(true);
                                  // setSendingVideoModal(true);
                                  setSendingPrivacyModal(true);
                                }
                              }}
                            >
                              선택한 영상 전달하기
                            </div>
                          </div>
                          <div className="maximizeButton">
                            <img
                              src={maximize}
                              onClick={() => {
                                setMaximizeModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {(user?.testReport?.status !== "incompleteTest") &
                (user?.testReport?.status !== "uploadNotCompleted") &
                (user?.testReport?.status !== "errorCase") ? (
                  <div className="detectedGraphBox">
                    <div className="title">신체/환경 요소 별 감지 데이터</div>
                    <div className="bodyInfo">
                      <div className="forBody">
                        <Body props={user?.testReport?.analysis} />
                      </div>
                      <Graph data={user?.testReport?.analysis} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="environmentBucket">
                  <div className="environmentForTestBox">
                    <div className="titlePart">
                      <div className="title">주변환경 스캔 내역</div>
                      {settingPhoto?.aroundSetting?.reactivateTestPhotos
                        ?.length > 1 && (
                        <div className="filterForRetake">
                          <div
                            className="retakeFilterItemOne"
                            style={{ cursor: "pointer" }}
                            onClick={() => setEnvironmentFilter(1)}
                          >
                            시작 직전
                          </div>
                          <div className="middleBar" />
                          <div
                            className="retakeFilterItemTwo"
                            style={{ cursor: "pointer" }}
                            onClick={() => setEnvironmentFilter(2)}
                          >
                            응시 중 재점검
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="photoPart">
                      {environmentFilter === 1 && (
                        <img
                          className="tempered"
                          style={{ cursor: "pointer" }}
                          src={settingPhoto?.aroundSetting?.preTestPhoto}
                          onClick={() => {
                            setPhotoModalList([
                              settingPhoto?.aroundSetting?.preTestPhoto,
                            ]);
                            setPhotoModal(true);
                            setPhotoTitle("주변환경 스캔 내역");
                            setPhotoSubTitle("응시 중 재점검");
                          }}
                          alt=""
                        />
                      )}
                      {environmentFilter === 2 && (
                        <img
                          className="tempered"
                          style={{ cursor: "pointer" }}
                          src={
                            settingPhoto?.aroundSetting
                              ?.reactivateTestPhotos?.[0]
                          }
                          onClick={() => {
                            setPhotoModalList(
                              settingPhoto?.aroundSetting?.reactivateTestPhotos,
                            );
                            setPhotoModal(true);
                            setPhotoTitle("주변환경 스캔 내역");
                            setPhotoSubTitle("응시 중 재점검");
                          }}
                          alt=""
                        />
                      )}
                      {(settingPhoto?.aroundSetting?.reactivateTestPhotos
                        ?.length >
                        1) &
                      (environmentFilter === 2) ? (
                        <div className="photoCount">
                          +{" "}
                          {settingPhoto?.aroundSetting?.reactivateTestPhotos
                            ?.length - 1}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {isEdit ? (
                      <DivTwoPartImprove
                        display="flex"
                        justify="space-between"
                        align_items="center"
                      >
                        <DivTwoPartImprove opacity={scanVerified ? 0.2 : 1}>
                          <TextLine
                            text={
                              "금지 물건이 감지되어 확인 및 검토가 필요해요."
                            }
                            fontcolor="#C83B38"
                          />
                        </DivTwoPartImprove>
                        {scanVerified ? (
                          <WeekSmallButton
                            border={true}
                            text="금지물건 감지안됨"
                            onClick={() => setScanVerified(false)}
                          />
                        ) : (
                          <NegativeButton
                            border={true}
                            text="금지물건 감지"
                            onClick={() => setScanVerified(true)}
                          />
                        )}
                      </DivTwoPartImprove>
                    ) : (
                      scanVerified === false && (
                        <div className="warningPart">
                          <div className="warningText">
                            금지 물건이 감지되어 확인 및 검토가 필요해요.
                          </div>
                          {settingPhoto?.aroundSetting?.reactivateTestPhotos
                            ?.length > 1 && (
                            <div className="infoText">
                              * 가장 마지막 내역부터 순차적으로 확인이 가능해요.
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div className="environmentForTestBox">
                    <div className="titlePart">
                      <div className="title">카메라 거치 내역</div>
                      {settingPhoto?.cameraSetting?.reactivateTestPhotos
                        ?.length >= 1 && (
                        <div className="filterForRetake">
                          <div
                            className="retakeFilterItemThree"
                            style={{ cursor: "pointer" }}
                            onClick={() => setCameraSettingFilter(1)}
                          >
                            시작 직전
                          </div>
                          <div className="middleBar" />
                          <div
                            className="retakeFilterItemFour"
                            style={{ cursor: "pointer" }}
                            onClick={() => setCameraSettingFilter(2)}
                          >
                            응시 중 재점검
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="photoPart">
                      {cameraSettingFilter === 1 && (
                        <img
                          style={{ cursor: "pointer" }}
                          src={settingPhoto?.cameraSetting?.preTestPhoto}
                          onClick={() => {
                            setPhotoModalList([
                              settingPhoto?.cameraSetting?.preTestPhoto,
                            ]);
                            setPhotoModal(true);
                            setPhotoTitle("카메라 거치내역");
                            setPhotoSubTitle("응시 중 재점검");
                          }}
                          alt=""
                        />
                      )}
                      {cameraSettingFilter === 2 && (
                        <img
                          style={{ cursor: "pointer" }}
                          src={
                            settingPhoto?.cameraSetting
                              ?.reactivateTestPhotos?.[0]
                          }
                          onClick={() => {
                            setPhotoModalList(
                              settingPhoto?.cameraSetting?.reactivateTestPhotos,
                            );
                            setPhotoModal(true);
                            setPhotoTitle("카메라 거치내역");
                            setPhotoSubTitle("응시 중 재점검");
                          }}
                          alt=""
                        />
                      )}
                      {(settingPhoto?.cameraSetting?.reactivateTestPhotos
                        ?.length >
                        1) &
                      (cameraSettingFilter === 2) ? (
                        <div className="photoCount">
                          +{" "}
                          {settingPhoto?.cameraSetting?.reactivateTestPhotos
                            ?.length - 1}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {cameraSettingFilter === 2 ? (
                      <TextLine
                        text="* 가장 마지막 내역부터 순차적으로 확인이 가능해요."
                        margin="15px 0 0 "
                        fontsize={16}
                        lineheight={24}
                        fontcolor="#444"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* 편집 기능 추가 */}
                {isEdit && (
                  <DivTwoPartImprove
                    display="flex"
                    align_items="center"
                    justify="center"
                    pc_margin="60px 0 0 0"
                    gap={24}
                  >
                    <WeekButton
                      text="취소"
                      width="169px"
                      onClick={() => {
                        setIsEdit(false);
                        setScanVerified(prevScanVerified);
                        setIsDelList(prevDelList);
                        setCodeCount(prevCodeCount);
                        setDisabledData(false);
                      }}
                    />
                    <PrimaryButton
                      text="저장"
                      size="big"
                      width="168px"
                      onClick={reportItemsUpdate}
                    />
                  </DivTwoPartImprove>
                )}
              </>
            )}
          </div>
        </div>
      </StBack>
    </>
  );
};

const StBack = styled.div`
  @media ${device.pc} {
    /* width: 100vw; */
    max-width: 100%;
    background-color: #f0f1f2;
    .onlyForMobile {
      display: none !important;
    }
    .upTitle {
      .flexing {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 19px;
        font-size: 28px;
        font-family: "Bold";
        line-height: 41px;
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
      .users {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 239px;
        height: 41px;
        background-color: #ffffff;
        border-radius: 21px;
        box-sizing: border-box;
        cursor: pointer;
        .studentShowingOfThisPage {
          display: flex;
          align-items: center;
          padding: 9px 16px;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          font-family: "regular";
          .naming {
            margin-left: 10px;
            line-height: 24px;
          }
          .counting {
            margin: 0 20px 0 auto;
            line-height: 24px;
            font-size: 16px;
            font-family: "medium";
          }
          .state {
            width: 14px;
            height: 14px;
            border-radius: 7px;
            background-color: ${(props) => props.stating};
          }
        }
        .studentListBox {
          position: absolute;
          z-index: 20;
          width: 100%;
          display: flex;
          flex-direction: column;
          max-height: 494px;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
          border-top-right-radius: 20.5px;
          border-top-left-radius: 20.5px;
          font-size: 16px;
          font-family: "regular";
          .studentOfThisPage {
            position: absolute;
            color: #2276dc;
            background: #ffffff;
            border-top-right-radius: 20.5px;
            border-top-left-radius: 20.5px;
            border-bottom-left-radius: ${(props) =>
              props.studentCount > 1 ? "" : "20.5px"};
            border-bottom-right-radius: ${(props) =>
              props.studentCount > 1 ? "" : "20.5px"};
            right: 0px;
            display: flex;
            align-items: center;
            padding: 14px 24px;
            box-sizing: border-box;
            border-bottom: 1px solid #dfdfdf;
            width: 312px;
            height: 52px;
            .naming {
              margin-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              white-space: nowrap;
            }
            .counting {
              margin: 0 20px 0 auto;
            }
            .state {
              width: 14px;
              height: 14px;
              border-radius: 7px;
              background-color: ${(props) => props.stating};
            }
          }
          .listing {
            /* position : absolute;
                        z-index: 30; */
            top: 52px;
            width: 312px;
            overflow-x: hidden;
            ::-webkit-scrollbar {
              width: 5px;
              height: 10px;
              margin-top: 100px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #c6c6c6;
              border-radius: 100px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 1rem;
            }
          }
        }
      }
    }
    .reportContent {
      position: relative;
      width: 1108px;
      padding: 114px 60px 60px;
      margin-top: 140px;
      background-color: white;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      .title {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        margin-bottom: 15px;
      }
      .userPhoto {
        position: absolute;
        top: -100px;
        left: 60px;
        width: 180px;
        height: 180px;
        border: 6px solid #ffffff;
        box-sizing: border-box;
      }
      .testerInformationBox {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 36px;
        .eachItemBox {
          display: grid;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          box-sizing: border-box;
          .titlePart {
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: center;
            gap: 10px;
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
            color: black;
            img {
              width: 16px;
              cursor: pointer;
            }
          }
          .bodyPart {
            font-size: 18px;
            font-family: "regular";
            line-height: 27px;
            color: #444444;
          }
          .namePart {
            font-size: 16px;
            font-family: "regular";
            line-height: 24px;
            color: #444444;
          }
          .emailPart {
            font-size: 18px;
            font-family: "regular";
            line-height: 27px;
            color: #2276dc;
            text-decoration: underline;
          }
        }
        .lineUp {
          display: flex;
          flex-direction: column;
          min-height: 83px;
          padding: 14px 0;
          box-sizing: border-box;
        }
        .left {
          color: #55595f;
          font-size: 12.5px;
        }
        .right {
          display: flex;
          align-items: center;
          padding: 6px 0;
          box-sizing: border-box;
          font-family: "Medium";
          min-height: 36px;
        }
        .blue {
          color: #2276dc;
          display: flex;
          align-items: center;
          font-family: "Medium";
          padding: 6px 0;
          /* height : 36px; */
          width: auto;
          box-sizing: border-box;
          text-decoration: underline;
        }
      }
      .synthesizeOpinionBox {
        width: 100%;
        box-sizing: border-box;
        margin-top: 60px;
        .bodyPart {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 15px;
          .talk {
            flex-grow: 1;
            margin-left: 10px;
            .messageBalloon {
              display: flex;
              flex-direction: column;
              width: 100%;
              background-color: #fff5da;
              border-radius: 12px;
              padding: 44px;
              box-sizing: border-box;
              border-radius: 12px;
              background-size: cover;
              .mainMessage {
                font-size: 24px;
                font-family: "bold";
                line-height: 36px;
              }
              .subMessage {
                margin: 10px 0 20px;
                line-height: 24px;
                font-family: "Regular";
                font-size: 16px;
              }
              .timeMessage {
                display: flex;
                margin-bottom: 6px;
                line-height: 24px;
                span {
                  margin-left: 25px;
                  color: #2276dc;
                }
              }
              .notificationMessage {
                color: #828282;
                font-size: 12px;
                font-family: "regular";
                line-height: 18px;
              }
            }
          }
        }
        .image {
          width: 142px;
          height: 132px;
        }
      }
      .detectedEventBox {
        width: 100%;
        box-sizing: border-box;
        margin-top: 60px;
        .bodyPart {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 32px;
          .total {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            place-items: center;
            width: 356px;
            height: 208px;
            margin-top: 39px;
            box-sizing: border-box;
            .inside {
              display: grid;
              flex-direction: column;
              gap: 8px;
              .what {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                font-family: "regular";
                line-height: 27px;
              }
              .circleRed {
                width: 10px;
                height: 10px;
                border-radius: 8px;
                margin-right: 6px;
                background-color: #c83b38;
              }
              .circleYellow {
                width: 10px;
                height: 10px;
                margin-right: 6px;
                border-radius: 8px;
                background-color: #ffbc00;
              }
              .down {
                margin: 0 auto;
                .num {
                  font-size: 40px;
                  font-family: "medium";
                  line-height: 59px;
                }
              }
            }
          }
          .bodyRight {
            display: flex;
            flex-direction: column;
            .rawDataLine {
              display: grid;
              grid-template-columns: 1fr auto;
              align-items: center;
              gap: 12px;
              font-family: "regular";
              font-size: 15px;
              line-height: 24px;
              margin: 0 0 15px auto;
              .rawDataCheckBox {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                border-radius: 4px;
                border: 0.6px solid #818181;
                background-color: ${(props) =>
                  props.rawData ? "#20315B" : ""};
              }
            }
            .eventTable {
              display: grid;
              grid-template-rows: 1fr;
              width: 724px;
              min-height: 208px;
              border: 1px solid #d6d9dd;
              box-sizing: border-box;
              .tableHeader,
              .tableMargin {
                height: 46px;
                border-bottom: 1px solid #d6d9dd;
                background-color: #fafafa;
                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .tableMargin {
                height: 10px;
                background-color: white;
                border-bottom: none;
              }
              .accCount {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-left: 1px solid #d6d9dd;
                border-right: 1px solid #d6d9dd;
                text-align: center;
              }
              .tableShowMore,
              .tableHeader,
              .tableMargin,
              .noEvent,
              .fillEvent {
                display: grid;
                grid-template-columns: 522px 100px 100px;
                box-sizing: border-box;
              }
              .fillEvent {
                height: 40px;
                .middleCount {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  height: 100%;
                  border-left: 1px solid #d6d9dd;
                  border-right: 1px solid #d6d9dd;
                }
              }
              .noEvent {
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 162px;
                .middleCount {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  height: 100%;
                  border-left: 1px solid #d6d9dd;
                  border-right: 1px solid #d6d9dd;
                }
              }
              .tableShowMore {
                height: 40px;
                .eventMore {
                  display: flex;
                  align-items: center;
                  width: max-content;
                  padding: 8px 38px;
                  text-decoration: underline;
                  color: #2276dc;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .detectedVideoBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        margin-top: 60px;
        .timeLinePart {
          /** 티켓ID 83 관련 추가 */
          position: relative;
          .timeLineInfo {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: "regular";
            line-height: 18px;
            .rightInfo {
              display: grid;
              grid-template-columns: auto auto;
              gap: 12px;
              .infoItemForm {
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                gap: 6px;
                .circle {
                  width: 5px;
                  height: 5px;
                  border-radius: 5px;
                }
              }
            }
          }
          .timeLineGraphInfo {
            width: 100%;
            height: 56px;
            border-right: 1px solid #444444;
            border-left: 1px solid #444444;
            margin: 16px 0 24px;
            .fromStartToEnd {
              display: flex;
              justify-content: space-between;
              font-size: 10px;
              font-family: "regular";
              line-height: 15px;
              color: #818181;
              padding: 0 4px;
              box-sizing: border-box;
            }
            .barGraph {
              position: relative;
              width: 100%;
              height: 15px;
              margin-top: 26px;
              background: rgba(140, 224, 174, 0.3);
              overflow: hidden;
              .unitBarPart {
                position: absolute;
                z-index: 10;
                bottom: 0;
                left: 0;
                display: flex;
                width: 100%;
                .unitBar {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 1px;
                  color: #aeaeae;
                  font-size: 8px;
                  line-height: 12px;
                  margin-left: calc(
                    100% * ${(props) => props.graphUnit / props.videoLength}
                  );
                  .columnStick {
                    width: 1px;
                    height: 21px;
                    border-left: 1px dashed #aeaeae;
                    box-sizing: border-box;
                  }
                }
              }
              .eventBarPart {
                position: absolute;
                /** 티켓ID 83 관련 추가 */
                /* z-index: 9; */
                z-index: 11;
                bottom: 15px;
                left: 0;
                display: flex;
                width: 100%;
              }
            }
          }
        }
        .rightShow {
          display: flex;
          align-items: center;
          font-family: "Regular";
          font-size: 16px;
        }
        .videoInfo {
          display: flex;
          flex-direction: row-reverse;
          margin-top: 15px;
          video {
            width: 529.5px;
            flex-grow: 1;
            height: 353px;
          }
          .cheatingList {
            display: flex;
            flex-direction: column;
            width: 578.5px;
            /* 티켓ID 88 관련 수정 */
            padding: 12px 9px 0;
            box-sizing: border-box;
            background-color: #f0f1f2;
            height: 353px;
            .videoLeftIn {
              width: 100%;
              height: 268px;
              overflow-x: hidden;
              border-radius: 10px;
              background-color: white;
              ::-webkit-scrollbar {
                width: 5px;
                height: 10px;
                margin-top: 100px;
                /* overflow: ${(props) =>
                  props.showScrollbar ? "" : "hidden"}; */
              }
              ::-webkit-scrollbar-thumb {
                background-color: #c6c6c6;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-track {
                border-radius: 1rem;
                /* background-color: black; */
              }
            }
            .videoLeftInTwo {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #bdbdbd;
              width: 100%;
              height: 268px;
              overflow-x: hidden;
              border-radius: 10px;
              background-color: white;
            }
            /** 티켓ID 77 관련 추가 */
            .videoButtonBox {
              position: relative;
              display: flex;
              justify-content: center;
              flex-grow: 1;
              align-items: center;
              .videoSharingButton {
                position: absolute;
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                gap: 6px;
                /* margin: 23px auto 0; */
                font-size: 16px;
                font-family: "medium";
                line-height: 24px;
                z-index: 9;
                color: ${(props) =>
                  props.$clickedEvent === 0
                    ? "rgba(34,118,220,0.5)"
                    : "#2276DC"};
                cursor: ${(props) =>
                  props.$clickedEvent === 0 ? "default" : "pointer"};
              }
              .maximizeButton {
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: right;
                /* margin: 26px 20px 0 0; */
                margin-right: 20px;
                z-index: 8;
                cursor: pointer;
              }
            }
          }
        }
      }
      .detectedGraphBox {
        margin-top: 60px;
        .bodyInfo {
          /* display: grid;
          grid-template-columns: auto 1fr;
          gap: 86.5px; */
          display: flex;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;
          margin-top: 38px;
          .forBody {
            width: 363px;
            margin-left: 56px;
          }
          .graph {
            //       position: relative;
            display: flex;
            width: 100%;
            height: 150px;
            justify-content: center;
            margin-top: 30px;
            .left {
              //       position: absolute;
              //           left : 10%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 50px;
              min-width: 50px;
              height: 122px;
              padding: 18px 0 2px 0;
              .line {
                display: flex;
                .tag {
                  font-family: "Regular";
                  font-size: 12px;
                }
                .circle1 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state1};
                  margin: auto 8px auto 0;
                }
                .circle2 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state2};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle3 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state3};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle4 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state4};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle5 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state5};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
              }
            }
            .right {
              //       position: absolute;
              font-size: 12px;
              //         right : -10px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              /* width : 56px; */
              min-width: 48px;
              height: 122px;
              padding: 18px 0 2px 0;
              .line {
                width: 100%;
              }
            }
          }
        }
      }
      .environmentBucket {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 28px;
        margin-top: 60px;
        .environmentForTestBox {
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          .titlePart {
            display: flex;
            justify-content: space-between;
            .title {
              margin-bottom: 0px;
            }
            .filterForRetake {
              display: grid;
              grid-template-columns: repeat(3, auto);
              align-items: center;
              gap: 12px;
              margin-top: 6px;
              .retakeFilterItemOne,
              .retakeFilterItemTwo,
              .retakeFilterItemThree,
              .retakeFilterItemFour {
                font-size: 12px;
                line-height: 18px;
              }
              .retakeFilterItemOne {
                color: ${(props) =>
                  props.environmentFilter === 1 ? "#2276DC" : ""};
              }
              .retakeFilterItemTwo {
                color: ${(props) =>
                  props.environmentFilter === 2 ? "#2276DC" : ""};
              }
              .retakeFilterItemThree {
                color: ${(props) =>
                  props.cameraSettingFilter === 1 ? "#2276DC" : ""};
              }
              .retakeFilterItemFour {
                color: ${(props) =>
                  props.cameraSettingFilter === 2 ? "#2276DC" : ""};
              }
              .middleBar {
                width: 1px;
                height: 12px;
                background-color: #c4c4c4;
              }
            }
          }
          .photoPart {
            position: relative;
            margin-top: 16px;
            width: 100%;
            .tempered {
              width: 100%;
              border: ${(props) =>
                props.tempered === false ? "2px solid #C83B38" : ""};
            }
            img {
              width: 540px;
              height: 293.76px;
            }
            .photoCount {
              position: absolute;
              top: 8px;
              right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 24px;
              font-size: 14px;
              font-family: "semibold";
              line-height: 21px;
              background-color: rgba(0, 0, 0, 0.6);
              border-radius: 18px;
              color: white;
            }
          }
          .warningPart {
            display: grid;
            flex-direction: column;
            margin-top: 5px;
            gap: 12px;
            .warningText {
              color: #c83b38;
              font-size: 12px;
              line-height: 18px;
            }
            .infoText {
              font-size: 11px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .all {
      color: ${(props) => (props.show === 1 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .cheating {
      color: ${(props) => (props.show === 2 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .abnormal {
      color: ${(props) => (props.show === 3 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .greyColumn {
      width: 1px;
      height: 16px;
      background-color: #c4c4c4;
      margin: 0 12px;
    }
    .rightShow {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .userVideo {
      background-color: black;
    }
    .talk {
      display: flex;
      align-items: center;
    }
    .triangle {
      width: 0px;
      height: 0px;
      border-right: 39px solid #fff4d8;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      margin: auto 0;
    }
    .listing {
      position: absolute;
      width: 312px;
      right: 0;
      top: 52px;
      max-height: 577px;
      overflow-x: hidden;
      background-color: #ffffff;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
      /* border-radius: 20.5px; */
      border-bottom-left-radius: 20.5px;
      border-bottom-right-radius: 20.5px;
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .just {
      position: relative;
    }
    .bar {
      display: flex;
      align-items: center;
      padding: 14px;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #e0e0df;
      background-color: white;
      border-radius: 24px;
      height: 41px;
    }
    .counting {
      margin: 0 16px 0 auto;
    }
    .name {
      margin-left: 10px;
    }
    .naming {
      width: 100px;
      white-space: nowrap;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stating {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: ${(props) => props.stating};
    }
    .state {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: ${(props) => props.stating};
    }
    .users {
      position: relative;
      font-size: 16px;
      font-family: "Regular";
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 239px;
      border-radius: 21px;
      box-sizing: border-box;
      margin: auto 0 auto auto;
      cursor: pointer;
    }
    .count {
      width: 98px;
      height: 38px;
      padding: 6px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #d6d9dd;
      border-right: 1px solid #d6d9dd;
    }
    .time {
      width: 98px;
      padding: 6px 0;
      box-sizing: border-box;
      text-align: center;
    }
    .actions {
      width: 520px;
      height: 50px;
      padding: 6px 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .actionsss {
      display: flex;
      justify-content: center;
      color: #bdbdbd;
      width: 520px;
      height: 50px;
      padding: 6px 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-family: "light";
    }
    .underLine {
      display: flex;
      width: 100%;
      height: 38px;
      line-height: 20px;
      margin-left: 10px;
      align-items: center;
      color: #2276dc;
      text-decoration: underline;
      cursor: pointer;
    }
    .lining {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
    }
    .upTitle {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-family: "Bold";
    }
    .lineUp {
      display: flex;
      align-items: center;
    }
    .body {
      width: 1228px;
      padding: 80px 0px;
      margin: 0 auto;
      background-color: #f0f1f2;
      .up {
        margin-bottom: 15px;
      }
      .down {
        margin: 60px 0 15px 0;
      }
    }
    .title {
      display: flex;
      font-family: "Medium";
      font-size: 18px;
    }
    .texts {
      position: absolute;
      top: 46%;
      left: 57%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 800px;
      span {
        color: #c83b38;
      }
    }
    .mainMessage {
      font-size: 24px;
      font-family: "Bold";
    }
    .subMessage {
      font-family: "Regular";
      margin: 10px 0 20px 0;
    }
    .notificationMessage {
      color: #828282;
      font-size: 12px;
      font-family: "Regular";
    }
    .line {
      display: flex;
      align-items: center;
    }
    .left {
      width: 180px;
      font-size: 18px;
      font-family: "Medium";
    }
    .right {
      width: 440px;
      margin-left: 24px;
      font-size: 18px;
      line-height: center;
    }
    .blue {
      width: auto;
      margin-left: 24px;
      font-size: 18px;
      text-decoration: underline;
      color: #2276dc;
    }
    .image {
      width: 142px;
      height: 132px;
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    background-color: #f0f1f2;
    .onlyForPc {
      display: none !important;
    }
    .reportContent {
      position: relative;
      width: 100%;
      min-height: 700px;
      padding: 70px 0 24px;
      box-sizing: border-box;
      margin-top: 80px;
      background-color: white;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      .title {
        font-size: 18px;
        font-family: "bold";
        line-height: 21px;
        margin-bottom: 24px;
      }
      .userPhoto {
        position: absolute;
        top: -60px;
        left: 24px;
        width: 120px;
        height: 120px;
        border: 4px solid #ffffff;
        box-sizing: border-box;
      }
      .testerInformationBox {
        width: 100%;
        .eachItemBox {
          display: grid;
          flex-direction: column;
          gap: 11px;
          width: 100%;
          padding: 14px 24px 21px;
          box-sizing: border-box;
          .titlePart {
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: center;
            gap: 6px;
            font-size: 13px;
            font-family: "regular";
            line-height: 19px;
            color: #55595f;
            img {
              width: 16px;
              cursor: pointer;
            }
          }
          .bodyPart {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
          }
          .namePart {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
          }
          .emailPart {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
            color: #2276dc;
            text-decoration: underline;
          }
        }
        .lineUp {
          display: flex;
          flex-direction: column;
          min-height: 83px;
          padding: 14px 0;
          box-sizing: border-box;
        }
        .left {
          color: #55595f;
          font-size: 12.5px;
        }
        .right {
          display: flex;
          align-items: center;
          padding: 6px 0;
          box-sizing: border-box;
          font-family: "Medium";
          min-height: 36px;
        }
        .blue {
          color: #2276dc;
          display: flex;
          align-items: center;
          font-family: "Medium";
          padding: 6px 0;
          /* height : 36px; */
          width: auto;
          box-sizing: border-box;
          text-decoration: underline;
        }
      }
      .synthesizeOpinionBox {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        .bodyPart {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .talk {
            .messageBalloon {
              background-size: cover;
              width: 100%;
              background-color: #fff5da;
              border-radius: 12px;
              padding: 24px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              .mainMessage {
                font-size: 18px;
                font-family: "bold";
                line-height: 27px;
              }
              .subMessage {
                margin: 16px 0 24px;
                line-height: 21px;
                font-family: "Regular";
                font-size: 14px;
              }
              .timeMessage {
                display: flex;
                margin-bottom: 6px;
                line-height: 24px;
                font-size: 14px;
                font-family: "medium";
                span {
                  font-family: "regular";
                  margin-left: 25px;
                  color: #2276dc;
                }
              }
              .notificationMessage {
                color: #828282;
                font-size: 12px;
                font-family: "light";
                line-height: 18px;
              }
            }
          }
        }
        .image {
          width: 142px;
          height: 132px;
        }
      }
      .detectedEventBox {
        width: 100%;
        padding: 14px 24px 24px;
        margin-top: 0;
        box-sizing: border-box;
        .bodyPart {
          .total {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            place-items: center;
            width: 100%;
            margin-bottom: 20px;
            box-sizing: border-box;
            .inside {
              display: grid;
              flex-direction: column;
              gap: 7px;
              .what {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                font-family: "medium";
                line-height: 18px;
              }
              .circleRed {
                width: 10px;
                height: 10px;
                border-radius: 8px;
                margin-right: 6px;
                background-color: #c83b38;
              }
              .circleYellow {
                width: 10px;
                height: 10px;
                margin-right: 6px;
                border-radius: 8px;
                background-color: #ffbc00;
              }
              .num {
                font-size: 24px;
                font-family: "Bold";
                line-height: 36px;
                margin: 0 auto;
              }
              .down {
                margin: 0 auto;
              }
            }
          }
          .bodyRight {
            display: flex;
            flex-direction: column;
            .rawDataLine {
              display: grid;
              grid-template-columns: auto auto;
              align-items: center;
              gap: 12px;
              font-family: "regular";
              font-size: 13px;
              line-height: normal;
              margin: 0 0 15px auto;
              .rawDataCheckBox {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 0.6px solid #818181;
                background-color: ${(props) =>
                  props.rawData ? "#20315B" : ""};
              }
            }
            .eventTable {
              display: grid;
              grid-template-rows: 1fr;
              width: 100%;
              border: 1px solid #d6d9dd;
              box-sizing: border-box;
              .tableHeader,
              .tableMargin {
                line-height: 18px;
                font-size: 12px;
                font-family: "medium";
                height: 46px;
                border-bottom: 1px solid #d6d9dd;
                background-color: #fafafa;
                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .tableMargin {
                height: 10px;
                border-bottom: none;
                background-color: white;
              }
              .accCount {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-left: 1px solid #d6d9dd;
                border-right: 1px solid #d6d9dd;
                text-align: center;
              }
              .tableShowMore,
              .tableHeader,
              .tableMargin,
              .fillEvent,
              .noEvent {
                display: grid;
                grid-template-columns: 62% 1fr 1fr;
                box-sizing: border-box;
              }
              .fillEvent {
                height: 35px;
                .middleCount {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  height: 100%;
                  border-left: 1px solid #d6d9dd;
                  border-right: 1px solid #d6d9dd;
                }
              }
              .noEvent {
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 50px;
                .middleCount {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  height: 100%;
                  border-left: 1px solid #d6d9dd;
                  border-right: 1px solid #d6d9dd;
                }
              }
              .tableShowMore {
                height: 40px;
                .eventMore {
                  display: flex;
                  align-items: center;
                  width: max-content;
                  padding: 8px 28px;
                  font-size: 13px;
                  line-height: 19px;
                  text-decoration: underline;
                  color: #2276dc;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .detectedVideoBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 14px 24px 24px;
        box-sizing: border-box;
        .timeLinePart {
          /** 티켓ID 83 관련 추가 */
          position: relative;
          .timeLineInfo {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: "regular";
            line-height: 18px;
            .rightInfo {
              display: grid;
              grid-template-columns: auto auto;
              gap: 12px;
              .infoItemForm {
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                gap: 6px;
                .circle {
                  width: 5px;
                  height: 5px;
                  border-radius: 5px;
                }
              }
            }
          }
          .timeLineGraphInfo {
            width: 100%;
            height: 56px;
            border-right: 1px solid #444444;
            border-left: 1px solid #444444;
            margin: 16px 0 24px;
            .fromStartToEnd {
              display: flex;
              justify-content: space-between;
              font-size: 10px;
              font-family: "regular";
              line-height: 15px;
              color: #818181;
              padding: 0 4px;
              box-sizing: border-box;
            }
            .barGraph {
              position: relative;
              width: 100%;
              height: 15px;
              margin-top: 26px;
              background: rgba(140, 224, 174, 0.3);
              overflow: hidden;
              .unitBarPart {
                position: absolute;
                z-index: 10;
                bottom: 0;
                left: 0;
                display: flex;
                width: 100%;
                .unitBar {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 1px;
                  color: #aeaeae;
                  font-size: 8px;
                  line-height: 12px;
                  margin-left: calc(
                    100% * ${(props) => props.graphUnit / props.videoLength}
                  );
                  .columnStick {
                    width: 1px;
                    height: 21px;
                    border-left: 1px dashed #aeaeae;
                    box-sizing: border-box;
                  }
                }
              }
              .eventBarPart {
                position: absolute;
                /** 티켓ID 83 관련 추가 */
                /* z-index: 9; */
                z-index: 11;
                bottom: 15px;
                left: 0;
                display: flex;
                width: 100%;
              }
            }
          }
        }
        .rightShow {
          display: flex;
          align-items: center;
          font-family: "Regular";
          font-size: 13px;
        }
        .videoInfo {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          .cheatingList {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* 티켓ID 88 관련 수정 */
            padding: 12px 9px 0;
            box-sizing: border-box;
            background-color: #f0f1f2;
            height: 307px;
            /** 티켓ID 77 관련 추가 */
            .videoButtonBox {
              position: relative;
              display: flex;
              justify-content: center;
              flex-grow: 1;
              align-items: center;
              .videoSharingButton {
                position: absolute;
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                gap: 6px;
                /* margin: 16px auto 0; */
                font-size: 13px;
                font-family: "medium";
                line-height: 19px;
                color: #2276dc;
                z-index: 9;
                opacity: ${(props) => (props.$clickedEvent === 0 ? "0.5" : "")};
                cursor: ${(props) =>
                  props.$clickedEvent === 0 ? "default" : "pointer"};
              }
              .maximizeButton {
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: right;
                /* margin: 17px 10px 0 0; */
                margin-right: 10px;
                z-index: 8;
                cursor: pointer;
              }
            }
          }
          .videoLeftIn {
            width: 100%;
            height: 244px;
            overflow-x: hidden;
            border-radius: 10px;
            background-color: white;
            ::-webkit-scrollbar {
              width: 5px;
              height: 10px;
              margin-top: 100px;
              /* overflow: ${(props) =>
                props.showScrollbar ? "" : "hidden"}; */
              display: ${(props) => (props.showScrollbar ? "" : "none")};
            }
            ::-webkit-scrollbar-thumb {
              background-color: #c6c6c6;
              border-radius: 100px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 1rem;
            }
          }
          .videoLeftInTwo {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #bdbdbd;
            width: 100%;
            height: 244px;
            overflow-x: hidden;
            border-radius: 10px;
            background-color: white;
          }
          .right {
            width: 100%;
          }
        }
      }
      .detectedGraphBox {
        padding: 14px 24px;
        .bodyInfo {
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          .graph {
            //       position: relative;
            display: flex;
            width: 100%;
            height: 150px;
            justify-content: center;
            margin-top: 30px;
            .left {
              //       position: absolute;
              //           left : 10%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 50px;
              min-width: 50px;
              height: 122px;
              padding: 18px 0 2px 0;
              .line {
                display: flex;
                .tag {
                  font-family: "Regular";
                  font-size: 12px;
                }
                .circle1 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state1};
                  margin: auto 8px auto 0;
                }
                .circle2 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state2};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle3 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state3};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle4 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state4};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
                .circle5 {
                  width: 5px;
                  height: 5px;
                  border-radius: 4px;
                  background-color: ${(props) => props.state5};
                  margin-right: 8px;
                  margin: auto 8px auto 0;
                }
              }
            }
            .right {
              //       position: absolute;
              font-size: 12px;
              //         right : -10px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              /* width : 56px; */
              min-width: 48px;
              height: 122px;
              padding: 18px 0 2px 0;
              .line {
                width: 100%;
              }
            }
          }
        }
      }
      .environmentForTestBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 14px 24px;
        margin-top: 10px;
        box-sizing: border-box;
        .title {
          margin-bottom: 0px;
        }
        .filterForRetake {
          display: grid;
          grid-template-columns: repeat(3, auto) 1fr;
          align-items: center;
          gap: 12px;
          margin-top: 6px;
          .retakeFilterItemOne,
          .retakeFilterItemTwo,
          .retakeFilterItemThree,
          .retakeFilterItemFour {
            font-size: 12px;
            line-height: 18px;
          }
          .retakeFilterItemOne {
            color: ${(props) =>
              props.environmentFilter === 1 ? "#2276DC" : ""};
          }
          .retakeFilterItemTwo {
            color: ${(props) =>
              props.environmentFilter === 2 ? "#2276DC" : ""};
          }
          .retakeFilterItemThree {
            color: ${(props) =>
              props.cameraSettingFilter === 1 ? "#2276DC" : ""};
          }
          .retakeFilterItemFour {
            color: ${(props) =>
              props.cameraSettingFilter === 2 ? "#2276DC" : ""};
          }
          .middleBar {
            width: 1px;
            height: 12px;
            background-color: #c4c4c4;
          }
        }
        .photoPart {
          position: relative;
          margin-top: 16px;
          width: 100%;
          .tempered {
            width: 100%;
            height: 66.66%;
            border: ${(props) =>
              props.tempered === false ? "2px solid #C83B38" : ""};
          }
          img {
            width: 100%;
            height: 100%;
          }
          .photoCount {
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 24px;
            font-size: 14px;
            font-family: "semibold";
            line-height: 21px;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 18px;
            color: white;
          }
        }
        .warningPart {
          display: grid;
          flex-direction: column;
          margin-top: 5px;
          gap: 12px;
          .warningText {
            color: #c83b38;
            font-size: 12px;
            line-height: 18px;
          }
          .infoText {
            font-size: 11px;
            line-height: 16px;
          }
        }
      }
    }
    .all {
      margin-left: 10px;
      color: ${(props) => (props.show === 1 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .cheating {
      color: ${(props) => (props.show === 2 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .abnormal {
      color: ${(props) => (props.show === 3 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .greyColumn {
      width: 1px;
      height: 16px;
      background-color: #c4c4c4;
      margin: 0 12px;
    }
    .userVideo {
      background-color: black;
      width: 100%;
      height: 100%;
      max-height: 289px;
    }
    .upTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 19px;
      font-family: "Bold";
      .users {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 52px;
        background-color: #ffffff;
        border-radius: 26px;
        box-sizing: border-box;
        margin-top: 38px;
        cursor: pointer;
        .studentShowingOfThisPage {
          display: flex;
          align-items: center;
          padding: 14px 24px;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
          .naming {
            width: 188px;
            margin-left: 10px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
          }
          .counting {
            margin: 0 20px 0 auto;
            line-height: 24px;
            font-size: 16px;
            font-family: "medium";
          }
          .state {
            width: 14px;
            height: 14px;
            border-radius: 7px;
            background-color: ${(props) => props.stating};
          }
          img {
            margin: 0 4px;
          }
        }
        .studentListBox {
          position: absolute;
          z-index: 20;
          width: 100%;
          display: flex;
          flex-direction: column;
          max-height: 494px;
          background: #ffffff;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
          border-radius: 20.5px;
          font-size: 16px;
          font-family: "regular";
          .studentOfThisPage {
            color: #2276dc;
            right: 0px;
            display: flex;
            align-items: center;
            padding: 14px 24px;
            box-sizing: border-box;
            border-bottom: 1px solid #dfdfdf;
            width: 100%;
            height: 52px;
            line-height: 24px;
            .naming {
              width: 164px;
              margin-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              white-space: nowrap;
            }
            .counting {
              margin: 0 20px 0 auto;
            }
            .state {
              width: 14px;
              height: 14px;
              border-radius: 7px;
              background-color: ${(props) => props.stating};
            }
          }
          .listing {
            /* position : absolute;
                        z-index: 30; */
            top: 52px;
            width: 100%;
            overflow-x: hidden;
            ::-webkit-scrollbar {
              width: 5px;
              height: 10px;
              margin-top: 100px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #c6c6c6;
              border-radius: 100px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 1rem;
            }
          }
        }
      }
      .flexing {
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        line-height: 28px;
        gap: 12px;
        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }
    .underLine {
      font-size: 13px;
      display: flex;
      width: 100%;
      height: 38px;
      line-height: 20px;
      margin-left: 10px;
      align-items: center;
      color: #2276dc;
      text-decoration: underline;
      cursor: pointer;
    }
    .forBody {
      width: 100%;
      max-width: 422px;
      margin: 0 auto;
    }
    .lining {
      display: grid;
      grid-template-columns: 62% 1fr 1fr;
      width: 100%;
      height: 35px;
      align-items: center;
    }
    .circl {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${(props) => props.detect};
    }
    .body {
      width: 100%;
      max-width: 752px;
      padding: 24px 24px 44px;
      box-sizing: border-box;
      margin: 0 auto;
      .up {
        margin-bottom: 15px;
      }
      .down {
        margin: 60px 0 15px 0;
      }
    }
    .detectedEventBox {
      width: 100%;
      min-height: 268px;
      margin-top: 24px;
      .Inside {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: px;
      }
    }
    .triangle {
      margin: 5px auto 0;
      width: 0;
      height: 0;
      border-bottom: 19.5px solid #fff5da;
      border-top: 0px solid transparent;
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
    }
    .texts {
      position: absolute;
      top: 46%;
      left: 57%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 800px;
      span {
        color: #c83b38;
      }
    }
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      height: auto;
      line-height: center;
    }
  }
`;

export default ReSpUp;
