import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface buttonProps {
  text: string;
  size: 'small' | 'big';
  width?: string;
  margin?: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface buttonStyle {
  size: string;
  width?: string;
  height: number;
  margin?: string;
}

const PrimaryButton: React.FC<buttonProps> = (props) => {
  const [height, setHeight] = useState<number>(39);

  useEffect(() => {
    if (props.size === 'small') {
      setHeight(39);
    } else if (props.size === 'big') {
      setHeight(52);
    }
  }, []);

  return (
    <StButton
      onClick={props.onClick}
      height={height}
      width={props.width}
      margin={props.margin}
      size={props.size}
      disabled={props.disabled}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonStyle>`
  min-width: 135px;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  font-family: medium;
  font-size: 17px;
  line-height: 25px;
  background-color: var(--primary_fill);
  padding: 8px 12px;
  margin: ${(props) => props.margin};
  box-sizing: border-box;
  color: var(--text_filledBtn);
  border-radius: 43px;
  border: none;
  &:hover {
    background-color: var(--primary_hover);
  }
  &:active {
    background-color: var(--primary_actived);
  }
  &:disabled {
    background-color: var(--primary_disabled);
    cursor: default;
  }
  cursor: pointer;
`;

export default PrimaryButton;
