import React, { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import Button from "../atom/Button";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import OnChangeInput from "../atom/OnchangeInput";
import { SetURLSearchParams, useLocation } from "react-router-dom";

interface SearchProps {
  searchRef: RefObject<HTMLInputElement>;
  searchParams: URLSearchParams;
  setSearchString: Dispatch<SetStateAction<string>>;
  setSearchParams: SetURLSearchParams;
}

const SearchBox: React.FC<SearchProps> = ({
  searchRef,
  searchParams,
  setSearchString,
  setSearchParams,
}) => {
  const location = useLocation();

  const submit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const onSearch = () => {
    const newParams = new URLSearchParams();
    if (searchRef.current?.value) {
      newParams.set("search", searchRef.current?.value);
      setSearchString(searchRef.current?.value);
    } else {
      setSearchString("");
    }
    setSearchParams(newParams);
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = searchParams.get("search") ?? "";
      setSearchString(searchRef.current.value);
    }
  }, [location]);

  return (
    <DivTwoPartImprove display="flex" gap={16}>
      <OnChangeInput
        inputRef={searchRef}
        padding="8px 16px"
        width="319px"
        border_radius={4}
        placeholderText="검색어를 입력해주세요"
        border="1px solid #D7D7D7"
        placeholdercolor="#BBB"
        onKeyDown={submit}
      />
      <Button
        padding="4px 20px"
        width="68px"
        height={40}
        text="검색"
        border_radius={4}
        backgroundcolor="#20315B"
        font_family="medium"
        font_color="#FFFFFF"
        onclick={onSearch}
      />
    </DivTwoPartImprove>
  );
};

export default SearchBox;
