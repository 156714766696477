import { useEffect, useRef, useState } from "react";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import OrangeButton from "../atomic/atom/OrangeButton";
import TextLine from "../atomic/atom/TextLine";
import LoginInputSection from "../atomic/molecule/LoginInputSection";
import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "../Cookie";
import jwtDecode from "jwt-decode";
import { DecodedAdminToken } from "../interface/authInterface";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../api/handler";
import axios from "axios";
import useToast from "../hooks/useToast";

const AdminLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const loginSubmitRef = useRef<HTMLButtonElement>(null);
  const utcInfo = new Date().getTimezoneOffset();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailErr, setEmailErr] = useState<string>("");
  const [pwErr, setPwErr] = useState<string>("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [pwFocus, setPwFocus] = useState(false);

  const token = getCookie("token");

  // const role = token ? (jwtDecode(token) as DecodedAdminToken).role : undefined;

  const mutate = async () => {
    // eslint-disable-next-line no-useless-escape
    const regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (regExp.test(email)) {
      setEmailErr("");
    } else {
      setEmailErr(t("errorCode.emailform_error"));
      emailRef.current?.focus();
      setEmailFocus(true);
      return;
    }

    axios
      .post(`${baseURL}/api/v1/admin/login?offset=${utcInfo}`, {
        email,
        password,
      })
      .then(({ data }) => {
        setCookie("token", data?.accessToken, {
          path: "/",
        });
        navigate(`/admin/list`);
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "HOST-001") {
          setEmailErr(t("errorCode.host_001"));
          emailRef.current?.focus();
          setEmailFocus(true);
        } else if (errorCode === "INCORECT-DB-PWD") {
          setPwErr(t("errorCode.incorect_db_pwd"));
          passwordRef.current?.focus();
        } else {
          showToast({
            message: t("errorCode.unknown_server_error"),
          });
        }
      });
  };

  const submit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      mutate();
    }
  };

  const btnDisabled = () => {
    if (email && password) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body?.addEventListener("click", (e) => {
      if (
        e.target !== emailRef.current &&
        e.target !== loginSubmitRef.current
      ) {
        setEmailFocus(false);
        setEmailErr("");
      }
      if (e.target !== passwordRef.current) {
        setPwFocus(false);
        setPwErr("");
      }
    });
  }, []);

  useEffect(() => {
    if (token && (jwtDecode(token) as DecodedAdminToken).role === "admin") {
      navigate(`/admin/list`);
    }
  }, [token]);

  return (
    <DivTwoPartImprove
      display="flex"
      justify="center"
      align_items="center"
      padding="80px 106px"
      background_color="#F8F8F8"
      // TODO: 헤더 또는 푸터 빠질 시 다시 계산
      // min_height="calc(100vh - 353px)"
      min_height="calc(100vh - 76px)"
    >
      <DivTwoPartImprove
        display="flex"
        flex_direction="column"
        width="445px"
        border_radius={12}
        background_color="#FFF"
        padding="48px"
        box_shadow="0px 2px 4px 0px rgba(0, 0, 0, 0.10);"
        gap={50}
        align_items="center"
      >
        <TextLine
          text={"프록토매틱 관리자 로그인"}
          fontsize={24}
          fontFamily="semiBold"
          textAlign="center"
        />
        <LoginInputSection
          emailRef={emailRef}
          passwordRef={passwordRef}
          emailErr={emailErr}
          pwErr={pwErr}
          hostEmail={email}
          hostPassword={password}
          emailFocus={emailFocus}
          pwFocus={pwFocus}
          setEmailErr={setEmailErr}
          setPwErr={setPwErr}
          setHostEmail={setEmail}
          setHostPassword={setPassword}
          setEmailFocus={setEmailFocus}
          setPwFocus={setPwFocus}
          handleKeyDown={submit}
        />
        <OrangeButton
          buttonRef={loginSubmitRef}
          text="로그인"
          onclick={() => mutate()}
          disabled={btnDisabled()}
        />
      </DivTwoPartImprove>
    </DivTwoPartImprove>
  );
};

export default AdminLogin;
