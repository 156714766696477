import React, { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  HoverToolTip,
  HoverToolTipModal,
  SelectedToolTip,
  SelectedToolTipModal,
  clickInfo,
  modalPadding,
} from "../atoms";
import styled from "styled-components";
import { device } from "../hooks/device";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import WeekSmallButton from "../atomic/molecule/Button/WeekSmallButton";
import NegativeButton from "../atomic/molecule/Button/NegativeButton";

const Action = ({
  index,
  setClickedEvent,
  clickedEvent,
  text,
  timestamp,
  startTime,
  endTime,
  setPauTime,
  clearTimeOut,
  level,
  setCurTime,
  setStartTime,
  setDuration,
  detectedItem,
  scrollContainerRef,
  isEdit,
  isDeleted,
  isDelList,
  setIsDeleted,
  setCodeCount,
  addEditID,
  removeEditID,
  setItemClicked,
  onKeyDownOnItemList,
}) => {
  // const [clickIndex, setClickIndex] = useRecoilState(clickInfo);
  // const clicked = clickIndex?.index;

  const [showLevel, setShowLevel] = useState();
  const [detect, setDetect] = useState("#3C9C9C");

  /** 티켓ID 77 관련 추가 */
  const modalOn = useRecoilValue(modalPadding);

  // 티켓ID 83 관련 추가
  let setHoverToolTip;
  let setSelectedToolTip;
  /** 티켓ID 77 관련 추가 */
  // const setHoverToolTip = useSetRecoilState(HoverToolTip);
  // const setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  if (modalOn) {
    setHoverToolTip = useSetRecoilState(HoverToolTipModal);
    setSelectedToolTip = useSetRecoilState(SelectedToolTipModal);
  } else {
    setHoverToolTip = useSetRecoilState(HoverToolTip);
    setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  }
  const scrollRef = useRef();

  // /** 아이템 리스트 클릭 했을 때 State set 모음 */
  // const setItemClicked = (index) => {
  //   setClickIndex({ index });
  //   setClickedEvent(index + 1);
  //   setCurTime(startTime);
  //   setStartTime(startTime);
  //   setDuration(endTime - startTime);
  //   clearTimeOut();
  //   setPauTime((endTime - startTime) * 1000);
  //   // resumeTime(startTime,(endTime-startTime)*1000)
  //   // 티켓ID 83 관련 추가
  //   setSelectedToolTip({
  //     isSelected: true,
  //     start: startTime,
  //     end: endTime,
  //     level: level,
  //     index: index,
  //   });
  // };

  // /** 아이템 리스트에서 키보드 처리 */
  // const onKeyDownOnItemList = (event) => {
  //   let cindex;
  //   switch (event.key) {
  //     case "ArrowUp":
  //       cindex = Math.max(clickIndex - 1, 0);
  //       setItemClicked(cindex);
  //       break;
  //     case "ArrowDown":
  //       cindex = Math.min(clickIndex + 1, detectedList.length - 1);
  //       setItemClicked(cindex);
  //       break;
  //     case "e":
  //     case "E":
  //       if (isEdit) {
  //         setIsDelList((prevState) =>
  //           prevState.map((value, i) =>
  //             i === clickIndex
  //               ? {
  //                   id: value.id,
  //                   code: value.code,
  //                   isDisable: true,
  //                   firstState: value.firstState,
  //                 }
  //               : value,
  //           ),
  //         );
  //         if (!isDelList[clickIndex].isDisable) {
  //           setCodeCount((prev) => ({
  //             ...prev,
  //             [isDelList[clickIndex].code]:
  //               (prev[isDelList[clickIndex].code] || 0) + 1,
  //           }));
  //         }
  //       }
  //       break;
  //     case "r":
  //     case "R":
  //       if (isEdit) {
  //         setIsDelList((prevState) =>
  //           prevState.map((value, i) =>
  //             i === clickIndex
  //               ? {
  //                   id: value.id,
  //                   code: value.code,
  //                   isDisable: false,
  //                   firstState: value.firstState,
  //                 }
  //               : value,
  //           ),
  //         );
  //         if (isDelList[clickIndex].isDisable) {
  //           setCodeCount((prev) => ({
  //             ...prev,
  //             [isDelList[clickIndex].code]:
  //               (prev[isDelList[clickIndex].code] || 0) - 1,
  //           }));
  //         }
  //       }
  //       break;
  //     default:
  //       console.log("ETC!");
  //       break;
  //   }

  //   event.preventDefault();
  // };

  useEffect(() => {
    if (level === "cheat") {
      setDetect("#C83B38");
    } else if (level === "abnormal") {
      setDetect("#FFBC00");
    } else {
      setDetect("#3C9C9C");
    }
  }, [level]);

  useEffect(() => {
    if (level === "cheat") {
      setShowLevel("부정행위");
    } else if (level === "abnormal") {
      setShowLevel("이상행동");
    }
  }, [level]);

  // 티켓ID 83 관련 추가, 스크롤 이동
  useEffect(() => {
    if (clickedEvent === index + 1) {
      const container = scrollContainerRef?.current;
      const target = scrollRef?.current;

      const containerRect = container?.getBoundingClientRect();
      const targetRect = target?.getBoundingClientRect();

      const scrollTop =
        targetRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }, [clickedEvent]);

  return (
    <StBack
      ref={scrollRef}
      detect={detect}
      now={index + 1}
      clicked={clickedEvent}
      // 티켓ID 83 관련 추가
      onMouseEnter={() => {
        setHoverToolTip({
          isHover: true,
          start: startTime,
          end: endTime,
          level: level,
          index: index,
        });
      }}
      // 티켓ID 83 관련 추가
      onMouseLeave={() => {
        setHoverToolTip({ isHover: false });
      }}
      onClick={() => {
        setItemClicked(index);
      }}
      onKeyDown={(event) => onKeyDownOnItemList(event)}
      isDeleted={isDelList[index]?.isDisable}
    >
      <DivTwoPartImprove
        width="100%"
        display="flex"
        justify="space-between"
        align_items="center"
      >
        <div className="buckett">
          <span className="num">{String(index + 1).padStart(2, "0")}</span>
          <div className="upLine">
            <div className="action">
              {text} ({Math.floor(endTime - startTime)}초) {detectedItem}
            </div>
            <div className="downLine">
              <div className="evaluate">{showLevel}</div>
              <div className="greyBar" />
              <div className="timess">{timestamp}</div>
            </div>
          </div>
        </div>
        {isEdit && (
          <DivTwoPartImprove display="flex" align_items="center">
            {isDeleted ? (
              <WeekSmallButton
                border={true}
                text="복원"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsDeleted((prev) => {
                    const updateList = prev.map((value, i) => {
                      if (i === index) {
                        removeEditID(value.id);
                        value.firstState && addEditID(value.id);
                        return { ...value, isDisable: false };
                      }
                      // index === props.index
                      //   ? { id: value.id, code: value.code, isDisable: false }
                      //   : value
                      return value;
                    });

                    return updateList;
                  });
                  setCodeCount((prev) => ({
                    ...prev,
                    [isDelList[index].code]:
                      (prev[isDelList[index].code] || 0) - 1,
                  }));
                  if (scrollRef.current) {
                    scrollRef.current.focus();
                  }
                }}
              />
            ) : (
              <NegativeButton
                border={true}
                text="삭제"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsDeleted((prev) => {
                    const updateList = prev.map((value, i) => {
                      if (i === index) {
                        addEditID(value.id);
                        value.firstState && removeEditID(value.id);
                        return { ...value, isDisable: true };
                      }
                      // index === props.index
                      //   ? { id: value.id, code: value.code, isDisable: true }
                      //   : value
                      return value;
                    });

                    return updateList;
                  });
                  setCodeCount((prev) => ({
                    ...prev,
                    [isDelList[index].code]:
                      (prev[isDelList[index].code] || 0) + 1,
                  }));
                  if (scrollRef.current) {
                    scrollRef.current.focus();
                  }
                }}
              />
            )}
          </DivTwoPartImprove>
        )}
      </DivTwoPartImprove>
    </StBack>
  );
};

const StBack = styled.button`
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: ${(props) =>
    props.clicked === props.now ? "8px 7px" : "10px 10px"};
  box-sizing: border-box;
  border-radius: 10px;
  border: ${(props) =>
    props.clicked === props.now ? "2px solid #5C69E0" : "none"};
  line-height: 24px;
  .action {
    font-family: ${(props) =>
      props.clicked === props.now ? "bold" : "medium"};
    color: ${(props) => (props.clicked === props.now ? "#2638D6" : "")};
  }
  .upLine {
    color: #242424;
  }
  .buckett {
    display: flex;
    opacity: ${(props) => (props.isDeleted ? "0.2" : "1")};
    .num {
      color: ${(props) =>
        props.clicked === props.now ? "#2638D6" : "#818181"};
    }
  }
  .downLine {
    display: flex;
    align-items: center;
    .evaluate {
      color: ${(props) => props.detect};
      font-family: "Regular";
    }
    .greyBar {
      width: 0.5px;
      margin: 0 8px;
      height: 12px;
      background-color: #c4c4c4;
    }
    .timess {
      color: #818181;
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @media ${device.pc} {
    height: 77px;
    font-size: 16px;
    .buckett {
      display: grid;
      grid-template-columns: auto auto;
      gap: 16px;
      .num {
        font-family: ${(props) =>
          props.clicked === props.now ? "regular" : "light"};
      }
    }
    .upLine {
      display: grid;
      flex-direction: column;
      gap: 5px;
    }
  }
  @media ${device.tabMob} {
    min-height: 61px;
    .buckett {
      .num {
        font-size: 13px;
        margin-right: 8px;
        font-family: ${(props) =>
          props.clicked === props.now ? "Bold" : "Regular"};
      }
      .upLine {
        font-size: 13px;
      }
      .downLine {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
`;

export default Action;
