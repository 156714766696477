import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportList = ({ lang, testId, hostUuid, isAdmin }) => {
  const fetcher = async () => {
    const { data } = isAdmin
      ? await apis.getReportListAdmin({ testUid: testId, hostUid: hostUuid })
      : await apis.getReportList({ uid: testId });
    return data;
  };
  return useQuery("reportList", fetcher);
};

export default useGetReportList;
