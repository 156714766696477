import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportListEvents = ({ testId, hostUuid, isAdmin, lang }) => {
  const fetcher = async () => {
    const { data } = isAdmin
      ? await apis.getReportAIEventAdmin({ testUid: testId, hostUid: hostUuid })
      : await apis.getReportAIEvent({ uid: testId });
    return data;
  };
  return useQuery("reportListEvents", fetcher);
};

export default useGetReportListEvents;
