import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportUserList = ({ testId, hostUuid, isAdmin, isEdit }) => {
  const fetcher = async () => {
    const { data } = isAdmin
      ? await apis.getReportUserListAdmin({
          testUid: testId,
          hostUid: hostUuid,
        })
      : await apis.getReportUserList({ uid: testId });
    return data;
  };
  return useQuery(
    ["reportUserList", testId, hostUuid, isAdmin, isEdit],
    fetcher,
  );
};

export default useGetReportUserList;
