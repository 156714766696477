import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReport = ({
  testerId,
  testId,
  rawDataState,
  hostUuid,
  disabledData,
  isAdmin,
  isEdit,
}) => {
  const fetcher = async () => {
    // const utc = new Date().getTimezoneOffset();
    const { data } = isAdmin
      ? await apis.getReportAdmin({
          // utc,
          testerId,
          testId,
          rawDataState,
          hostUuid,
          disabledData,
        })
      : await apis.getReport({
          // utc,
          testerId,
          testId,
          rawDataState,
        });
    return data;
  };
  return useQuery(
    [
      "report",
      testId,
      testerId,
      rawDataState,
      hostUuid,
      disabledData,
      isAdmin,
      isEdit,
    ],
    fetcher,
  );
};

export default useGetReport;
