import styled from "styled-components";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import TextLine from "../atomic/atom/TextLine";

interface DropBoxProps {
  name?: string;
  email?: string;
  logout: () => void;
  close: () => void;
}

const DropBoxAdmin: React.FC<DropBoxProps> = ({
  name,
  email,
  logout,
  close,
}) => {
  return (
    <>
      <Back onClick={close} />
      <StDropBox>
        <DivTwoPartImprove display="flex" flex_direction="column">
          <DivTwoPartImprove
            display="grid"
            flex_direction="column"
            gap={3}
            width="100%"
            padding="12px"
            pc_box_sizing="border-box"
            cursor="default"
          >
            <DivTwoPartImprove display="flex" flex_direction="column" gap={3}>
              <DivTwoPartImprove display="flex" gap={5} align_items="center">
                <TextLine text={name} fontsize={18} fontFamily="semiBold" />
                <TextLine text="님" fontsize={14} fontcolor="#5C5C5C" />
              </DivTwoPartImprove>
              <TextLine text={email} fontsize={14} />
            </DivTwoPartImprove>
          </DivTwoPartImprove>
          <DivTwoPartImprove
            height={1}
            width="100%"
            background_color="#dfdfdf"
          />
          <DivTwoPartImprove
            display="flex"
            height={48}
            cursor="pointer"
            padding="0 12px"
            align_items="center"
            onclick={logout}
          >
            <TextLine
              text="로그아웃"
              fontFamily="semiBold"
              fontcolor="#5C5C5C"
              cursor={true}
            />
          </DivTwoPartImprove>
        </DivTwoPartImprove>
      </StDropBox>
    </>
  );
};

const Back = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: auto;
`;

const StDropBox = styled.div`
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 30px;
  right: -30px;
  z-index: 20;
  width: 225px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 0;
  color: black;
  font-family: "Regular";
`;

export default DropBoxAdmin;
