import { UseQueryResult, useQuery } from "react-query";
import getApi from "../../api/getApi";
import { GetHostsByAdmin } from "../../interface/apiInterface";

const useGetHostsByAdmin = ({
  page,
  limit,
  search,
}: {
  page: number;
  limit: number;
  search: string;
}): UseQueryResult<GetHostsByAdmin, unknown> => {
  const fetcher = async () => {
    const response = await getApi.getHostsByAdmin({ page, limit, search });
    return response.data;
  };
  return useQuery(["hostsByAdmin", page, limit, search], fetcher);
};

export default useGetHostsByAdmin;
